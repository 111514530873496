import { RoundingTypes } from '../rounding-dialog.component';

export default class RoundingConfigData {
  enabled: boolean;
  minutes: number;
  type: RoundingTypes;
  constructor(config: Partial<RoundingConfigData>) {
    Object.assign(this, config);
  }
}
