import { EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export const errorEventHandler = new EventEmitter<any>(true);
export const parseErrorArgs = (args: Record<string, any>, translate?: TranslateService) => {
  const isError = /^errors\./;
  if (translate)
    Object.entries(args).forEach(([key, value]) => {
      if (isError.test(value)) args[key] = translate.instant(value);
    });
  return args;
};
export const pushErrorWithOptions = (err: any, options: any, ...args: any[]) =>
  errorEventHandler.emit([err, options, ...args]);
export const pushError = (err: any, ...args: any[]) => pushErrorWithOptions(err, undefined, ...args);
