import { app, type app as teamsApp } from '@microsoft/teams-js';
import { Logger } from 'timeghost-api';
import { environment } from './environment';

export const checkTeamsQuery = () => {
  if (!environment.production && !!window.location.search?.match('in_teams_test=1')) return true;
  return !!window.location.search?.match('in_teams=1');
};
export const isTeamsWindow = () => {
  // if (!environment.production && window.teams_test) return true;
  return !!window.teams || !!checkTeamsQuery() || !!JSON.parse(sessionStorage.teams || 'false');
};
export function isTeamsContext(instance: any): instance is typeof teamsApp {
  return instance && '_initialize' in instance;
}
const teamsJsLog = new Logger('Teams JS');
export const initTeamsJS = () =>
  new Promise<typeof app>(async (resolve, reject) => {
    const { app } = await import('@microsoft/teams-js').then((x) => x.default);
    await app.initialize().catch(reject);
    resolve(app);
  })
    .then((teamsApp) => {
      window.microsoftTeams = teamsApp;
      window.teams = true;
      return teamsApp;
    })
    .then((teams) => {
      teamsJsLog.debug('instance', teams);
      return teams;
    })
    .then((teams) => {
      return teams.getContext();
    })
    .then((teamsContext: teamsApp.Context) => {
      window.teamsMobile = ['android', 'ios'].includes(teamsContext.app.host.clientType);
      app.notifySuccess();
      return window.microsoftTeams;
    })
    .catch((err) => {
      teamsJsLog.error(err);
      app.notifyFailure({ reason: app.FailedReason.Other, message: 'Something went wrong.' });
    });
