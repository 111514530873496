<div
  #projectContextMenuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="projectContextMenu"
  style="visibility: hidden; position: fixed"
  [style.left]="projectContextMenuPosition.x"
  [style.top]="projectContextMenuPosition.y"
></div>
<mat-dialog-content>
  <div class="searchWrapper flex flex-shrink-0 flex-col justify-stretch">
    <div class="searchInput flex flex-col items-start justify-center px-2">
      <mat-form-field appearance="fill" class="mat-form-field-compact mat-form-field-no-hint w-full">
        <input type="text" matInput [formControl]="searchinput" cdkFocusInitial tabindex="0" ignoreAutocomplete />
        <mat-icon inline matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <app-loader [isActive]="this.isLoading$ | async" [enableCircle]="true" [width]="20"></app-loader>
    <cdk-virtual-scroll-viewport itemSize="40" [minBufferPx]="300" [maxBufferPx]="800" [style.height.px]="380">
      <mat-list dense role="listbox" class="pb-8">
        <ng-container
          *cdkVirtualFor="let entry of this.data$filtered | async; let last = last; let first = first; trackBy: trackId"
        >
          <mat-divider *ngIf="entry.entityName === 'client' && !first"></mat-divider>
          <ng-container
            *ngTemplateOutlet="
              entry.entityName === 'client' ? clientItem : entry.entityName === 'project' ? projectItem : null;
              context: { entity: entry }
            "
          ></ng-container>
        </ng-container>
      </mat-list>
    </cdk-virtual-scroll-viewport>
  </div>
</mat-dialog-content>
<mat-dialog-actions
  class="mat-action-filled"
  [matTooltip]="'workspace.errors.admin-create-project' | translate"
  [matTooltipDisabled]="this.workspace$canManageProject | async"
>
  <button
    *ngIf="workspace$canManageProject | async"
    mat-flat-button
    color="primary"
    (click)="createProjectDialog()"
    [disabled]="isLoading$ | async"
    class="mat-button-filled"
  >
    {{ 'project.create' | translate }}
  </button>
</mat-dialog-actions>
<mat-menu #projectContextMenu="matMenu">
  <ng-template matMenuContent let-project>
    <div class="flex flex-col gap-1">
      <button mat-menu-item (click)="$event.stopPropagation(); togglePinnedProject(project.id)">
        <mat-icon inline>star</mat-icon>
        {{ (this.isPinned(project.id) ? 'project.fav-disable' : 'project.fav-enable') | translate }}
      </button>
      <a mat-menu-item *ngIf="!project.useAsDefault" [routerLink]="['/settings/projects', project.id]" matDialogClose>
        <mat-icon inline> open_in_new </mat-icon>
        {{ 'project.open' | translate }}
      </a>
    </div>
  </ng-template>
</mat-menu>
<ng-template #clientItem let-entry="entity">
  <h3 matSubheader>
    <span class="--client-title">{{
      entry.id === 'internal' ? (entry.name | translate) : (entry | defaultEntityTranslate: 'client' : 'client.none')
    }}</span>
  </h3>
</ng-template>
<ng-template #projectItem let-project="entity">
  <tg-client-project-entry
    [entry]="project"
    (contextMenu)="onContextMenu($event)"
    (selectItem)="onSelectItem($event)"
    (selectTaskItem)="onSelectTaskItem($event)"
    [tooltipTemplate]="projectPickerTooltip"
  ></tg-client-project-entry>
</ng-template>

<ng-template #projectPickerTooltip let-project="project" let-task="task" let-tags="tags">
  <div class="flex flex-row">
    <div class="my-2 flex flex-col space-y-2 px-2.5 wflex-[1_0_220px]">
      <div class="space-y-1">
        <h3 class="mat-hint text-sm italic" translate>client.single</h3>
        <div class="font-semibold">
          {{ project?.client | defaultEntityTranslate: 'client' : 'client.none' }}
        </div>
      </div>
      <div class="space-y-1">
        <h3 class="mat-hint text-sm italic" translate>project.single</h3>
        <div class="font-semibold">
          {{ project | defaultEntityTranslate: 'project' : 'project.none' }}
        </div>
      </div>
      <div class="space-y-1" *ngIf="(project | projectProxy)?.description as desc">
        <h3 class="mat-hint text-sm italic" translate>Description</h3>
        <div>{{ desc | truncate: 120 }}</div>
      </div>
      <ng-container *ngIf="tags && tags.length > 0">
        <div class="space-y-1">
          <h3 class="mat-hint text-sm italic" translate>tag.plural</h3>
          <div class="flex flex-wrap gap-2">
            <div
              class="mbg-card pointer-events-none h-6 cursor-default select-none truncate rounded-full px-2.5 text-base leading-6"
              *ngFor="let item of tags; trackBy: trackId"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
