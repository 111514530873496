import { ComponentType } from '@angular/cdk/portal';
import { AbstractControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'lodash-es';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { ensureClassIsDecorated } from './ensure-class-decorator';
import { translateArgs } from './translate-mutator';
const OBSERVABLE_FORM_ERROR_PROPNAME = '__formcontrol_observables';
export function ObserveFormGroupErrors(): ClassDecorator {
  return (_type: any) => {
    (<T>(type: ComponentType<T>): void => {
      type.prototype[OBSERVABLE_FORM_ERROR_PROPNAME] = {};
      type.prototype.__formcontrol_observableKill = new Subject<void>();
      type.prototype.ngOnDestroy = (function (ngOnDestroy: (() => void) | null | undefined) {
        return function (this: any) {
          ngOnDestroy && ngOnDestroy.call(this);
          const destroyNotify: Subject<void> = type.prototype.__formcontrol_observableKill;
          destroyNotify.next();
          destroyNotify.complete();
          type.prototype[OBSERVABLE_FORM_ERROR_PROPNAME] = {};
        };
      })(type.prototype.ngOnDestroy);
    })(_type);
  };
}
export interface FormErrorObservableOptions {
  initialValidate: boolean;
  useFEOptions: UseFormErrorObservableOptions;
}
type UseFormErrorObservableOptions = {
  respectState?: boolean;
};
export function useFormErrorObservable<T>(instance: T, useFEOptions?: UseFormErrorObservableOptions) {
  ensureClassIsDecorated(instance, OBSERVABLE_FORM_ERROR_PROPNAME);
  if (!useFEOptions) useFEOptions = {};
  return (
    keyName: string,
    func: () => AbstractControl,
    translations: { [key: string]: (errors: any, control: AbstractControl) => Omit<ControlErrorBag, 'target'> },
    translate: typeof TranslateService.prototype.instant,
    options?: Partial<FormErrorObservableOptions>
  ): Observable<ControlErrorBag> | Observable<ControlErrorBag[]> => {
    if (!keyName) {
      return combineLatest<ControlErrorBag[]>(Object.values(instance[OBSERVABLE_FORM_ERROR_PROPNAME]));
    }
    if (instance[OBSERVABLE_FORM_ERROR_PROPNAME][keyName]) return instance[OBSERVABLE_FORM_ERROR_PROPNAME][keyName];
    let ob = (instance[OBSERVABLE_FORM_ERROR_PROPNAME][keyName] = createErrorObservable(
      func,
      translations,
      translate,
      merge(options || {}, useFEOptions)
    ).pipe(takeUntil(instance['__formcontrol_observableKill'])));

    return ob;
  };
}
export function createErrorFunction(
  func: () => AbstractControl,
  translations: {
    [key: string]: (errors: any, control: AbstractControl) => Omit<ControlErrorBag, 'target'>;
  },
  translate: typeof TranslateService.prototype.instant
): ControlErrorBag {
  const control = func();
  const x = control.errors;
  if (!x) return null;
  const [key, translateByKey] = (typeof translations === 'object' &&
    Object.entries(translations).find(([_key]) => !!x[_key])) || [null, null];
  if (!translateByKey) return null;
  const value: ControlErrorBag = translateByKey(x[key], control) as any;
  if (value && translate && typeof value === 'object' && value?.args && Object.keys(value.args).length > 0)
    value.args = translateArgs(value.args, translate) as typeof value.args;
  value.target = key;
  return value;
}
export function createErrorObservable(
  func: () => AbstractControl,
  translations: {
    [key: string]: (errors: any, control: AbstractControl) => Omit<ControlErrorBag, 'target'>;
  },
  translate: typeof TranslateService.prototype.instant,
  options?: Partial<FormErrorObservableOptions>
): Observable<ControlErrorBag> {
  const control = func();
  return control.statusChanges.pipe(
    (source) => (options?.initialValidate ? source.pipe(startWith(control.status)) : source),
    (source) => (options?.useFEOptions?.respectState ? source.pipe(filter(() => control.enabled)) : source),
    map(() => control.errors),
    map((x) => {
      if (!x) return null;
      const [key, translateByKey] = (typeof translations === 'object' &&
        Object.entries(translations).find(([_key]) => !!x[_key])) || [null, null];
      if (!translateByKey) return null;
      const errorBag = translateByKey(x[key], control);
      if (errorBag && x.index !== undefined) {
        return { ...errorBag, index: x.index, target: key } as any;
      }
      return errorBag;
    }),
    map((x: ControlErrorBag) => {
      if (x && translate && typeof x === 'object' && x?.args && Object.keys(x.args).length > 0)
        x.args = translateArgs(x.args, translate) as typeof x.args;
      return x;
    })
  );
}
export function updateAndValidateAllChildren<T extends FormGroup>(
  group: T,
  opt?: Parameters<AbstractControl['updateValueAndValidity']>['0']
) {
  return Object.values(group.controls).forEach((ctrl) => {
    ctrl.updateValueAndValidity(opt);
  });
}
export function modifyAllControls<T extends FormGroup>(group: T, fn: (ctrl: AbstractControl) => void) {
  return Object.values(group.controls).forEach(fn);
}

export interface ControlErrorBag<K extends '' = any> {
  content: string;
  args?: {
    [key: string]: any;
  };
  target: K;
}
