import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[app-el-ref],[el-ref],[elementRef]',
  exportAs: 'nativeElement'
})
export class ElRefDirective {
  constructor(private el: ElementRef) {}
  get elementRef() {
    return this.el;
  }
}
