import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeUrl'
})
export class SafeUrlPipe implements PipeTransform {
  constructor(private sanit: DomSanitizer) {}
  transform(value: any, ...args: any): any {
    return this._transform(value, args);
  }
  private _transform(value: string, [type]: ['url' | 'frame' | undefined | null]) {
    return !type || type === 'frame'
      ? this.sanit.bypassSecurityTrustResourceUrl(value)
      : type === 'url'
      ? this.sanit.bypassSecurityTrustUrl(value)
      : null;
  }
}
