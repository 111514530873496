import { Component, OnInit, Inject } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { TagsService, Tag, TagType, TagsQuery, UserSettingsQuery } from 'timeghost-api';
import { UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { finalize, distinctUntilChanged, map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { CustomValidators } from '@app/_validators/custom-validators';
import { AppService } from '@app/app.service';
import errorUtils from '@app/_classes/error-utils';
import { hasPermission } from '@app/_helpers/utils';

@Component({
  selector: 'app-create-tag',
  templateUrl: './create-tag.component.html',
  styleUrls: ['./create-tag.component.scss'],
})
export class CreateTagComponent implements OnInit {
  group = new UntypedFormGroup(
    {
      name: new UntypedFormControl('', [Validators.required, CustomValidators.minLength(3)]),
      tagType: new UntypedFormControl(TagType.Time, [Validators.required]),
    },
    {
      validators: [
        ({ root: ctrl }) => {
          const ctrls = (ctrl as UntypedFormGroup).controls;
          if (
            this.tagsQuery.getCount(
              (x) => x.tagType === TagType.Time && x.name?.toLowerCase() === `${ctrls.name.value}`.toLowerCase(),
            ) > 0
          ) {
            ctrls.name.setErrors({ exists: true });
            return { exists: true };
          }
          return null;
        },
      ],
      updateOn: 'change',
    },
  );
  readonly workspace$createPermission = this.userSettingsQuery.select().pipe(
    map((userSettings) => {
      return !!userSettings.workspace.permissionSettings?.groupsCanManageTags?.find((x) =>
        hasPermission(x.id, userSettings),
      );
    }),
  );
  private _isLoading = new BehaviorSubject<boolean>(false);
  readonly isLoading$ = this._isLoading.asObservable().pipe(distinctUntilChanged());
  get isLoading() {
    return this._isLoading.getValue();
  }
  set isLoading(val: boolean) {
    this._isLoading.next(val);
  }

  constructor(
    private tagService: TagsService,
    private tagsQuery: TagsQuery,
    private ref: MatDialogRef<CreateTagComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: { name?: string; type?: TagType; allowTypeModify?: boolean },
    private appService: AppService,
    private userSettingsQuery: UserSettingsQuery,
  ) {}

  ngOnInit() {
    this.ref.updateSize('420px');
    this.ref.addPanelClass(['mat-dialog-vanilla', 'mat-dialog-relative']);
    this.group.setValue({
      name: this.data?.name ?? '',
      tagType: this.data?.type ?? TagType.Time,
    });
    if (this.data.allowTypeModify === false) this.group.controls.tagType.disable();
  }
  get formNameErrors() {
    if (this.group.controls.name.errors?.required) return { content: 'errors.required', args: { field: 'Name' } };
    if (this.group.controls.name.errors?.minlength)
      return { content: 'errors.minlength', args: { field: 'Name', length: 3 } };
    if (this.group.controls.name.errors?.exists) return { content: 'errors.unique', args: { field: 'Name' } };
    return null;
  }
  createNewTag() {
    this.isLoading = true;
    const value = this.group.getRawValue();
    this.tagService
      .add({ name: value.name, tagType: value.tagType })
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe(
        (tag) => this.ref.close(tag),
        (err) => {
          if (errorUtils.isUniqueError(err)) {
            this.group.controls.name.setErrors({
              exists: true,
            });
            this.isLoading = false;
          }
        },
      );
  }
}
