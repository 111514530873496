import { Component, OnInit, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, debounceTime, startWith, skip, map, first } from 'rxjs/operators';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import RoundingConfigData from './models/rounding-config-data';
import RoundingConfig from './models/rounding-config';
import { AppService } from '@app/app.service';
import { distinctUntilChangedJson } from '@app/_helpers/utils';

@Component({
  selector: 'app-rounding-dialog',
  templateUrl: './rounding-dialog.component.html',
  styleUrls: ['./rounding-dialog.component.scss'],
})
export class RoundingDialogComponent implements OnInit {
  roundingGrp = new FormGroup({
    roundingState: new FormControl(false),
    roundingType: new FormControl(RoundingTypes.Nearest),
    roundingValue: new FormControl(15),
  });
  readonly roundingTypes: typeof RoundingTypes = RoundingTypes;
  getRoundingTypes$() {
    return Object.keys(this.roundingTypes).filter((x) => typeof this.roundingTypes[x] === 'number');
  }
  private roundingType = new BehaviorSubject(RoundingTypes.Nearest);
  readonly roundingType$ = this.roundingType.asObservable().pipe(startWith(1), distinctUntilChanged());
  constructor(
    @Inject(MAT_DIALOG_DATA)
    private config: RoundingConfig,
    private appService: AppService,
    private ref: MatDialogRef<RoundingDialogComponent>,
  ) {
    if (!this.config || !this.config.data) {
      this.config = new RoundingConfig({
        data: {
          enabled: false,
          minutes: 15,
          type: RoundingTypes.Nearest,
        },
      });
    }
    this.roundingGrp.patchValue({
      roundingState: !!this.config.data.enabled,
      roundingType: this.config.data.type,
      roundingValue: this.config.data.minutes,
    });
    this.roundingType.next(this.config.data.type.valueOf());
  }
  get hideToggle() {
    return this.config.hideToggle;
  }
  ngOnInit() {
    this.ref.updateSize('400px');
    this.ref.addPanelClass(['mat-dialog-vanilla', 'mat-dialog-relative']);
    this.roundingGrp.controls.roundingState.valueChanges
      .pipe(startWith(this.roundingGrp.value.roundingState), distinctUntilChangedJson())
      .subscribe((x: boolean) => {
        [this.roundingGrp.controls.roundingType, this.roundingGrp.controls.roundingValue].forEach((y) =>
          !!x && y.disabled ? y.enable() : !x && y.enabled ? y.disable() : null,
        );
      });
  }
  parseDisplayRoundingType(type: RoundingTypes) {
    return (
      {
        [RoundingTypes.UpTo]: 'Round up to',
        [RoundingTypes.DownTo]: 'Round down to',
        [RoundingTypes.Nearest]: 'Round to nearest',
      }[type] ?? '-'
    );
  }
  submit() {
    let data = new RoundingConfigData({
      enabled: !!this.roundingGrp.controls.roundingState.value,
      minutes: this.roundingGrp.controls.roundingValue.value,
      type: this.roundingGrp.controls.roundingType.value as RoundingTypes,
    });
    this.ref.close(data);
  }
  durArray = {
    values: [5, 6, 10, 12, 15, 30, 60, 240],
    ref: 0,
  };
}
export enum RoundingTypes {
  UpTo,
  Nearest,
  DownTo,
}
