import { DOCUMENT } from '@angular/common';
import { EventEmitter, Inject, Injectable, Optional } from '@angular/core';
import { EntityStore, PersistState, resetStores } from '@datorama/akita';
import { environment } from '@env/environment';
import { isTeamsWindow } from '@env/msal';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { endOfDay, startOfDay } from 'date-fns/esm';
import { MediaObserver } from 'ngx-flexible-layout';
import { BehaviorSubject, Subscription, forkJoin, fromEvent } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  Client,
  ClientsQuery,
  ClientsService,
  FeedQuery,
  FeedService,
  Logger,
  MyTimesQuery,
  MyTimesService,
  NotifyService,
  Project,
  ProjectsQuery,
  ProjectsService,
  TagsService,
  Time,
  UserService,
  UserSettingsQuery,
  Workspace,
  WorkspacesQuery,
  WorkspacesService,
} from 'timeghost-api';
import { MyTimesStore } from 'timeghost-api/lib/stores/myTimes/myTimes.store';

import { HttpErrorResponse } from '@angular/common/http';
import { DateRange } from '@angular/material/datepicker';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { WIDGET_ID as FRILL_WIDGET_ID, initFrillJs } from '@env/frill';
import { merge } from 'lodash-es';
import { ExternalToast, toast } from 'ngx-sonner';
import { ColorScheme } from './_classes/color-scheme';
import color from './_helpers/color';
import { debounceTimeAfterFirst } from './_helpers/debounceAfterTime';
import { errorEventHandler } from './_helpers/globalErrorHandler';
import { toPromise } from './_helpers/promise';
import { createRxValue, fromRxValue, resolveRawArgs } from './_helpers/utils';
import { ConfirmDialogComponent } from './components/generic-dialogs/confirm-dialog/confirm-dialog.component';
import { SONNER_DEFAULT_CONFIG } from './config/sonner';
import { FeedPageService } from './pages/feed-page/feed.service';
import { HomeService } from './pages/home-page/home.service';
import wakeup from './services/on-weakup/wakeup';
import { TeamsService } from './services/teams.service';
import { APP_ASSIGN_COLOR_SCHEME } from './shared/color-schemes/office-scheme';
import RoundingConfigData from './shared/dialogs/rounding-dialog/models/rounding-config-data';
import { RoundingTypes } from './shared/dialogs/rounding-dialog/rounding-dialog.component';
import { RecordToolbarService } from './shared/record-toolbar/record-toolbar.service';

declare const window: Window;
const log = new Logger('AppService');
export type ThemeType = 'dark' | 'light' | 'default';
type EntityHasWorkspace = {
  workspace: { id: string };
};
@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class AppService {
  readonly parentHost = createRxValue<'webex' | 'teams' | 'web'>('web');
  colorScheme = new ColorScheme(APP_ASSIGN_COLOR_SCHEME);
  events = new EventEmitter<[string, ...any[]]>(true);
  readonly isMobile$ = this.media
    .asObservable()
    .pipe(map((x) => x.findIndex((y) => ['xs', 'sm'].includes(y.mqAlias)) !== -1));
  get isMobile() {
    return this.media.isActive(['xs', 'sm']);
  }

  emitEvent(event: string, ...args: any[]) {
    return this.events.emit([event, ...args]);
  }
  isCDKActive() {
    return !!this.document.querySelector(`.cdk-overlay-container .cdk-overlay-pane`);
  }
  private _isOnline = new BehaviorSubject<boolean>(true);
  readonly isOnline$ = this._isOnline.asObservable().pipe(distinctUntilChanged());
  get isOnline() {
    return this._isOnline.getValue();
  }
  set isOnline(val: boolean) {
    this._isOnline.next(val);
  }
  readonly browserWoken = new EventEmitter<number>(true);

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    @Optional()
    @Inject('persistStorage')
    private persistStore: PersistState,
    public notifier: NotifierService,
    private translate: TranslateService,
    private userSettingsQuery: UserSettingsQuery,
    private userService: UserService,
    private signalService: NotifyService,
    private workspaceQuery: WorkspacesQuery,
    private feedService: FeedService,
    private feedQuery: FeedQuery,
    private feedPageService: FeedPageService,
    private media: MediaObserver,
    private clientsService: ClientsService,
    private projectsService: ProjectsService,
    private tagsService: TagsService,
    private myTimesService: MyTimesService,
    private recordService: RecordToolbarService,
    private workspaceService: WorkspacesService,
    private teamsService: TeamsService,
    private myTimesQuery: MyTimesQuery,
    private projectsQuery: ProjectsQuery,
    private clientsQuery: ClientsQuery,
    private msalBroadcast: MsalBroadcastService,
    private dialog: MatDialog,
    private home: HomeService,
    private router: Router,
  ) {
    if (!localStorage.theme) localStorage.theme = 'default';
  }
  readonly comegoEnabled$ = this.userSettingsQuery.select().pipe(map((x) => !!x?.workspace?.settings?.comego));
  readonly comegoOnly = fromRxValue(
    this.userSettingsQuery.select().pipe(map((x) => x?.workspace?.settings?.comegoOnly === true)),
  );
  readonly comegoOnly$ = this.comegoOnly.asObservable();
  readonly authState = fromRxValue(this.msalBroadcast.inProgress$);
  storesLoading = false;
  reinitializeStores(
    ignore?: Partial<['workspaces', 'projects']>,
    options?: Partial<{ feedKeepTime: boolean; disableRemove: boolean }>,
  ) {
    const today = new Date();
    const user = this.userSettingsQuery.getValue();
    return new Promise<boolean>(async (resolve, reject) => {
      (this.myTimesQuery.__store__ as MyTimesStore).updateState({ nextDate: 'init' });
      ((range) => this.home.fetchBetween(range.start, range.end))(this.home.range.value);
      options?.feedKeepTime
        ? this.feedPageService.reloadCalendar()
        : this.feedPageService.updateRange(new DateRange(startOfDay(today), endOfDay(today)));
      if (!options?.disableRemove) {
        await Promise.resolve(
          (this.projectsQuery.__store__ as EntityStore).remove(
            (x: Project & EntityHasWorkspace) => x.workspace?.id && x.workspace.id !== user.workspace.id,
          ),
        ),
          await Promise.resolve(
            (this.clientsQuery.__store__ as EntityStore).remove(
              (x: Client & EntityHasWorkspace) => x.workspace?.id && x.workspace.id !== user.workspace.id,
            ),
          );
      }
      forkJoin([
        !ignore?.includes('workspaces') ? this.workspaceService.get().toPromise() : Promise.resolve<Workspace[]>([]),
        this.clientsService.get().toPromise(),
        !ignore?.includes('projects') ? this.projectsService.get().toPromise() : Promise.resolve<Project[]>([]),
        this.tagsService.get().toPromise(),
      ])
        .pipe(switchMap((x) => this.connectSignal(true).then(() => x)))
        .pipe(
          switchMap(([, , projects]) => {
            this.storesLoading = true;
            const handleTime = (time: Time[]) => {
              if (
                !time?.length &&
                (!this.recordService.group.value.project?.id ||
                  !projects.find((x) => x.id === this.recordService.group.value.project?.id)) &&
                projects?.find((x) => !!x?.useAsDefault)
              ) {
                this.recordService.group.patchValue({
                  project: projects.find((x) => !!x?.useAsDefault),
                  task: null,
                });
                return time[0];
              }
              return null;
            };
            return this.myTimesService.getLatestRecordings(1).then(handleTime).catch(handleTime);
          }),
        )
        .subscribe({
          next: () => {
            this.storesLoading = false;
            resolve(true);
          },
          error: (err) => reject(err),
        });
    });
  }
  resetStores() {
    if (localStorage)
      localStorage.removeItem('project_filter'),
        localStorage.removeItem('dashboard_filter'),
        localStorage.removeItem('projectSearch'),
        this.persistStore?.clearStore();
    resetStores({ exclude: [this.userSettingsQuery.__store__.storeName, this.workspaceQuery.__store__.storeName] });

    this.recordService.resetAll();
  }
  private _visibility = new BehaviorSubject<DocumentVisibilityState>('visible');
  readonly visibility$ = this._visibility.asObservable().pipe(distinctUntilChanged());
  get visibility() {
    return this._visibility.getValue();
  }
  set visibility(val: DocumentVisibilityState) {
    this._visibility.next(val);
  }
  readonly onResume = new EventEmitter(true);

  initialize() {
    fromEvent(document, 'onvisibilitychange').subscribe(
      () => (this.visibility = document.hidden ? 'hidden' : 'visible'),
    );
    fromEvent(document, 'resume').subscribe(() => this.onResume.emit());
    fromEvent(window, 'online').subscribe(() => (this.isOnline = true)),
      fromEvent(window, 'offline').subscribe(() => (this.isOnline = false));

    if (!environment.production) setTimeout(() => wakeup(() => this.browserWoken.emit(Date.now()))); // register on next tick

    errorEventHandler
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x && Array.isArray(x)),
      )
      .subscribe(([x, ...args]) => this.handleError(x, ...args));

    this.userSettingsQuery
      .select()
      .pipe(
        untilDestroyed(this),
        switchMap(async (user) => {
          // reload app if current user is not in workspace
          if (!user.workspace?.users?.find((x) => x.id === user.id))
            await this.router.navigateByUrl('/invalid-workspace', { replaceUrl: true });
          (this.myTimesQuery.__store__ as MyTimesStore)?.remove(
            (x) => !x.workspace || x.workspace.id !== user.workspace.id,
          );
          return true;
        }),
      )
      .subscribe();
    if (!environment.production) window.testRTC = () => this.signalActive.update(false);
  }
  readonly signalActive = createRxValue<boolean | null>(null);
  get isSignalInstanceConnected() {
    return this.signalService.isInstanceConnected();
  }
  get platform() {
    const m = navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|iOS|iMac/i)[0]?.toLowerCase();
    return m === 'android' ? 'android' : ['iphone', 'ipad', 'ipod', 'imac', 'ios', 'webos'].includes(m) ? 'ios' : null;
  }
  private translateArgs<T extends Object>(value?: T): T {
    if (!value || typeof value !== 'object') return {} as T;
    return Object.entries(value)
      .filter((val) => val?.length && val[0] && val[1])
      .reduce((acc, [key, value]) => {
        if (key && value) acc[key] = this.translate.instant(value);
        return acc;
      }, {} as T);
  }
  notifyError<T1 extends { [key: string]: any }>(body: string, options?: ExternalToast, args?: T1, rawArgs?: T1) {
    const messageArgs = merge(this.translateArgs(args || {}) || {}, rawArgs || {});
    toast.error(
      null,
      merge(
        {},
        { ...SONNER_DEFAULT_CONFIG },
        {
          duration: 30000,
          description: this.translate.instant(body, messageArgs),
        } as ExternalToast,
        options || {},
      ),
    );
  }
  notifyInfo(body: string, options?: ExternalToast, args?: { [key: string]: any }) {
    toast.info(
      null,
      merge({}, { ...SONNER_DEFAULT_CONFIG }, options || {}, {
        dismissible: true,
        duration: 4000,
        description: this.translate.instant(body, this.translateArgs(args || {})),
      } as ExternalToast),
    );
  }
  notifyWarn(body: string, options?: ExternalToast, args?: { [key: string]: any }) {
    toast.warning(
      null,
      merge(
        {},
        { ...SONNER_DEFAULT_CONFIG },
        {
          duration: 30000,
          description: this.translate.instant(body, this.translateArgs(args || {})),
        } as ExternalToast,
        options || {},
      ),
    );
  }
  notifySuccess(body: string, options?: ExternalToast, args?: { [key: string]: any }) {
    toast.success(
      null,
      merge({}, { ...SONNER_DEFAULT_CONFIG }, options || {}, {
        duration: 4000,
        description: this.translate.instant(body, this.translateArgs(args || {})),
      } as ExternalToast),
    );
  }
  public static defaultTimeFormat(): '24h' | 'AMPM' {
    const lc = Intl.DateTimeFormat().resolvedOptions().locale;
    const hasPeriodSuffix =
      new Intl.DateTimeFormat(lc, {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      })
        .formatToParts()
        .findIndex((x) => x.type === 'dayPeriod') !== -1;
    return hasPeriodSuffix ? 'AMPM' : '24h';
  }

  public get timezone(): string {
    return this.userSettingsQuery.getValue().settings.timeZone;
  }
  isTeams() {
    return !!window.teams || !!window['teams_test'] || isTeamsWindow();
  }
  private _isLoading = new BehaviorSubject<string[]>(null);
  readonly isLoading$ = this._isLoading.asObservable().pipe(
    distinctUntilChanged(),
    tap((x) => log.debug(JSON.stringify(x))),
  );
  get isLoading() {
    return this._isLoading.getValue();
  }
  set isLoading(val: string[]) {
    this._isLoading.next(val);
  }
  get isAMPM() {
    return !this.userSettingsQuery.getValue().settings.timeFormat24h;
  }
  formatAMPM(is24h?: boolean) {
    if (is24h === undefined) is24h = this.userSettingsQuery.getValue()?.settings?.timeFormat24h;
    if (typeof is24h !== 'boolean') {
      return AppService.defaultTimeFormat() === 'AMPM' ? 'hh:mm a' : 'HH:mm';
    }
    return is24h === true ? 'HH:mm' : 'hh:mm a';
  }
  get timeFormat() {
    return this.formatAMPM(this.userSettingsQuery.getValue().settings.timeFormat24h);
  }
  readonly timeFormat$ = this.userSettingsQuery.select().pipe(
    startWith(this.userSettingsQuery.getValue()),
    distinctUntilChanged(),
    shareReplay(),
    map((x) => this.formatAMPM(x.settings?.timeFormat24h)),
  );
  addLoading(id: string, clear: boolean = false) {
    if (clear) {
      this.isLoading = null;
    }
    if (this.isLoading) {
      this.isLoading = [...this.isLoading, id];
    } else {
      this.isLoading = [id];
    }
    return this.isLoading.length;
  }
  checkLoading(id: string) {
    return this.isLoading && this.isLoading.findIndex((x) => x === id) !== -1;
  }
  removeLoading(id: string) {
    if (!this.isLoading) {
      return 0;
    }
    const newLoadingObj = this.isLoading.filter((x) => x !== id);
    const currentLength = this.isLoading.length;
    this.isLoading = newLoadingObj;
    return currentLength - newLoadingObj.length;
  }
  toggleLoading(id: string) {
    return this.isLoading?.findIndex((x) => x === id) !== -1 ? this.removeLoading(id) : this.addLoading(id);
  }
  get selectedTheme(): ThemeType {
    const theme = this.userSettingsQuery.getValue()?.settings?.theme;
    if (!theme) return 'default';
    return ['default', 'dark', 'light'].includes(theme) ? theme : 'default';
  }
  readonly selectedThemeChange = new EventEmitter<ThemeType>(true);
  readonly selectedTheme$ = fromRxValue(
    this.selectedThemeChange.asObservable().pipe(
      startWith(this.selectedTheme),
      debounceTimeAfterFirst(50),
      distinctUntilChanged(),
      switchMap(() => this.getCurrentTheme()),
    ),
  );
  async getCurrentTheme(): Promise<ThemeType> {
    const current = this.selectedTheme;
    if (current === 'default') {
      return await new Promise((resolve, reject) => {
        if (this.isTeams() && window.microsoftTeams) {
          window.microsoftTeams.getContext((context: any) => {
            resolve(context.theme === 'dark' ? 'dark' : 'light');
          });
        } else if (window.matchMedia) {
          resolve(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
        } else {
          resolve('light');
        }
      });
    }
    return current;
  }
  async saveTheme(type: ThemeType) {
    let user = this.userSettingsQuery.getValue();
    user =
      user.settings.theme !== type
        ? await toPromise(this.userService.changeSettings({ theme: type } as any))
        : this.userSettingsQuery.getValue();
    this.selectedThemeChange.emit(user.settings.theme);
    return user.settings.theme;
  }
  private onLoadFinished(theme: ThemeType) {
    this.document.body.classList.remove('dark', 'light');
    this.document.body.classList.add(theme);
  }
  async loadThemeIfUnloaded() {
    const theme = await this.getCurrentTheme();
    await this.setMode(theme, true, false, false);
  }
  async setMode(type: ThemeType, forceUpdate?: boolean, showLoading: boolean = true, saveToUser = true) {
    let styles: HTMLLinkElement[] = Array.from(this.document.head.querySelectorAll('link[data-theme]'));
    const prevTheme = this.selectedTheme;
    if (type === 'default' && (forceUpdate === true || prevTheme !== 'default')) {
      if (this.isTeams() && window.microsoftTeams) {
        await this.teamsService
          .getInstance()
          ?.app.getContext()
          .then(async (context) => {
            if (!context.app?.theme) return await this.setMode('light', true);
            if (context.app.theme === 'dark') await this.setMode('dark', true, true);
          });
      } else if (window.matchMedia) {
        if (window.matchMedia('(prefers-color-scheme: dark)').matches) await this.setMode('dark', true);
        else await this.setMode('light', true);
      } else {
        await this.setMode('light');
      }
      if (saveToUser) await this.saveTheme('default');
      return;
    } else if (type === 'dark' && (forceUpdate === true || prevTheme !== 'dark')) {
      if (styles.findIndex((x: HTMLLinkElement) => x.dataset.theme === 'dark') === -1) {
        if (showLoading) this.addLoading('fullAppLoading', true);
        const stl = document.createElement('link');
        stl.rel = 'stylesheet';
        stl.href = 'app-dark.css';
        stl.dataset.theme = type;
        stl.dataset.themeRef = 'dark';
        stl.onload = () => {
          this.onLoadFinished(type);

          styles.forEach((x) => x.remove());
          if (showLoading)
            setTimeout(() => {
              this.removeLoading('fullAppLoading');
            }, 200);
        };
        this.document.head.insertBefore(stl, this.document.head.querySelector('style'));
      }
      if (saveToUser) await this.saveTheme(type);
    } else if (type === 'light' && (forceUpdate === true || prevTheme !== 'light')) {
      if (styles.findIndex((x: HTMLLinkElement) => x.dataset.theme === 'light') === -1) {
        if (showLoading) this.addLoading('fullAppLoading', true);
        const stl = document.createElement('link');
        stl.rel = 'stylesheet';
        stl.href = 'app.css';
        stl.dataset.theme = type;
        stl.dataset.themeRef = 'light';
        stl.onload = () => {
          this.onLoadFinished(type);
          styles.forEach((x) => x.remove());
          if (showLoading)
            setTimeout(() => {
              this.removeLoading('fullAppLoading');
            }, 200);
        };
        this.document.head.insertBefore(stl, this.document.head.querySelector('style'));
      }
      if (saveToUser) await this.saveTheme(type);
    }
    if (type !== 'default') document.documentElement.setAttribute('data-theme', type);
  }

  private _colorAssignments = new BehaviorSubject<IColorAssignment[]>([]);
  readonly colorAssignments$ = this._colorAssignments.asObservable().pipe(distinctUntilChanged());
  get colorAssignments() {
    return this._colorAssignments.getValue();
  }
  set colorAssignments(val: IColorAssignment[]) {
    this._colorAssignments.next(val);
  }
  getColorFromScheme(scheme: string[], rnd?: number) {
    const colorIndex = (rnd || 1) % APP_ASSIGN_COLOR_SCHEME.length;
    return APP_ASSIGN_COLOR_SCHEME[colorIndex];
  }
  dynamicColor(useCustomScheme?: boolean) {
    if (useCustomScheme === true) {
      const colorIndex = this.colorAssignments.length % APP_ASSIGN_COLOR_SCHEME.length;
      return color.fromPalette(APP_ASSIGN_COLOR_SCHEME[colorIndex]).rgbString();
    }
    let r = Math.floor(Math.random() * 255);
    let g = Math.floor(Math.random() * 255);
    let b = Math.floor(Math.random() * 255);
    return `rgba(${r},${g},${b}, .65)`;
  }
  setColorById(id: string, color?: string, prefix?: string) {
    if (prefix) id = prefix + '.' + id;
    if (this.colorAssignments?.findIndex((x) => x.id === id) !== -1) return;
    this.colorAssignments = [...this.colorAssignments, { id, color: color || this.dynamicColor(true) }];
  }
  getColorById(id: string, prefix?: string) {
    return this.colorAssignments?.find((x) => x.id === (prefix ? prefix + '.' : '') + id)?.color;
  }
  hasColorById(id: string, prefix?: string) {
    return !this.colorAssignments?.findIndex((x) => x.id === (prefix ? prefix + '.' : '') + id);
  }

  private _roundingData = fromRxValue<RoundingConfigData>(
    this.userSettingsQuery.select().pipe(map((x) => x?.settings?.rounding)),
    { enabled: false, minutes: 15, type: RoundingTypes.UpTo },
  );
  readonly roundingData$ = this._roundingData.asObservable().pipe(distinctUntilChanged());
  readonly selectedTimerView$ = this.userSettingsQuery
    .select()
    .pipe(map((x) => (['Simple', 'Grouped', 'Calendar'].includes(x.settings.tableDisplay) ? 'project' : 'work')));
  get roundingData() {
    return this._roundingData.value;
  }
  async updateRounding(data: RoundingConfigData) {
    return await toPromise(
      this.userService.changeSettings({
        rounding: data,
      } as any),
    ).then(({ settings }) => {
      this._roundingData.next(settings?.rounding);
    });
  }
  private signalInitSubscription: Subscription;
  async connectSignal(replaceHub?: boolean) {
    this.signalActive.update(null);
    const state = await this.signalService
      .createNewInstance(replaceHub === true || !this.signalService.isInstanceConnected())
      .catch((err) => {
        log.error(err);
        this.signalActive.update(false);
        throw err;
      });
    this.signalActive.update(true);

    return state;
  }
  initSignalReconnectOnFailed() {
    if (this.signalInitSubscription) this.signalInitSubscription.unsubscribe();
    this.signalInitSubscription = this.signalService.onHubClose$
      .pipe(
        debounceTime(1000),
        switchMap(() => this.connectSignal()),
      )
      .subscribe();
  }
  private infoErrors: string[] = []; // todo
  private resolveErrorField(key: string) {
    return (
      {
        task: 'task.select',
        project: 'project.select',
        tag: 'tag.select',
      }[key] || key
    );
  }
  handleError(err: any, options?: any, args: { [key: string]: any } = {}) {
    const aliasFields = {
      'errors.required': {
        task: 'errors.record.desc-required',
        project: 'errors.record.project-req',
        times: 'errors.times.comego.required',
      },
    };
    if (typeof err === 'string') return this.notifyError(err, options, args);
    else if (typeof err === 'object') {
      if (err instanceof HttpErrorResponse && !err.ok) {
        let errMsg: string;
        if (typeof err.error === 'object' && (errMsg = err.error?.message || err.error?.body)) {
          if (err.error.field) args.field = this.resolveErrorField(err.error.field);
          if (this.infoErrors.includes(errMsg.replace(/^error(s?)\./, '')))
            return this.notifyInfo(errMsg, options, args);
          let rawArgs = {};
          if ('args' in err.error && typeof err.error['args'] === 'object') rawArgs = merge(rawArgs, err.error['args']);
          if (err.error.field) errMsg = aliasFields[errMsg]?.[err.error.field] ?? errMsg;
          return this.notifyError(errMsg, options, args, resolveRawArgs(rawArgs));
        } else {
          if (err.status == 500) return this.notifyError('errors.server', options, args);
          else if (typeof err.error === 'string' && err.error?.match?.(/^error(s?)\./)) {
            if (this.infoErrors.includes(err.error.replace(/^error(s?)\./, '')))
              return this.notifyInfo(err.error, options, args);
            return this.notifyError(err.error, options, args);
          } else if (err.status == 401) return this.notifyError('utils.not-authorized', options, args);
          else if (typeof err.error === 'string') return this.notifyError(err.error, options, args);
          else if (typeof err.message === 'string') {
            return this.notifyError(err.message, options, args);
          }
        }
      }
      const message = err.message ?? err.content ?? 'Something went wrong...';
      if (message) return this.notifyError(message, options, args);
    }
    log.error('[pushErr]', err);
  }
  confirmDialog(text: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            text,
          },
          disableClose: true,
          closeOnNavigation: false,
          position: { top: '16px' },
          width: '520px',
        })
        .afterClosed()
        .subscribe(resolve);
    });
  }
  frillLoading = createRxValue(false);
  frillBadgeCount = createRxValue<number>();
  private frillInstance: any;
  async getFrillWidget() {
    const theme = await this.getCurrentTheme();
    const user = this.userSettingsQuery.getValue();
    const isAdmin = !!user.workspace.users.find((x) => x.admin && x.id === user.id);
    const ssoToken = await this.userService
      .getFrillSsoToken()
      .toPromise()
      .then((x) => x?.ssoToken)
      .catch(() => undefined);
    const getInstance = () =>
      initFrillJs(isAdmin ? FRILL_WIDGET_ID.ADMIN : FRILL_WIDGET_ID.NORMAL, {
        theme,
        ssoToken,
        onUpdate: (ev, data) => {
          if (ev === 'badgeCount') this.frillBadgeCount.value = data;
        },
      });
    if (this.frillInstance) {
      this.frillInstance.toggle();
      this.frillLoading.value = false;
      return;
    }
    this.frillLoading.value = true;
    return await getInstance().then((Frill: any) => {
      Frill.toggle();
      this.frillLoading.value = false;
      this.frillInstance = Frill;
    });
  }
  async setWebexUser(payload: { id: string; token: string; orgId: string; name: string; displayName: string }) {
    const user = this.userSettingsQuery.getValue()?.webex;
    const userId = user && user.userId + user.orgId;
    const payloadId = payload && payload.id + payload.orgId;
    if (!userId || userId !== payloadId) await this.userService.updateUserToken('webex', { ...payload, enabled: true });
  }
}
export interface IColorAssignment {
  id: string;
  color: string;
}
