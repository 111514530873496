import { Injectable } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { pushError } from '@app/_helpers/globalErrorHandler';
import { createRxValue } from '@app/_helpers/utils';
import { environment } from '@env/environment';
import {
  addDays,
  addMonths,
  differenceInDays,
  endOfToday,
  endOfISOWeek as endOfWeek,
  isFuture,
  isSameDay,
  isSameWeek,
  isWithinInterval,
  startOfToday,
  startOfISOWeek as startOfWeek,
  subDays,
  subMonths,
} from 'date-fns/esm';
import { filter, firstValueFrom, map } from 'rxjs';
import {
  ComegoQuery,
  ComegoService,
  ComegoStore,
  MyTimesQuery,
  MyTimesService,
  Time,
  UserSettingsQuery,
} from 'timeghost-api';
import { MyTimesStore } from 'timeghost-api/lib/stores/myTimes/myTimes.store';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  constructor(
    private userSettingsQuery: UserSettingsQuery,
    private myTimesService: MyTimesService,
    private myTimesQuery: MyTimesQuery,
    private comegoService: ComegoService,
    private comegoQuery: ComegoQuery
  ) {}

  readonly range = createRxValue<DateRange<Date>>(new DateRange(startOfWeek(new Date()), endOfWeek(new Date())));

  readonly minRangeStart = subMonths(startOfToday(), 3);
  readonly maxRangeStart = addMonths(endOfToday(), 6);
  readonly viewDate$ = this.range.asObservable().pipe(filter((x) => !!x?.start && !!x.end));
  readonly viewDate$status = this.viewDate$.pipe(
    map(({ start, end }) => {
      const sameDay = isSameDay(start, end),
        sameWeek = isSameWeek(start, end),
        isFutureTime = isFuture(end),
        isValidRange = isWithinInterval(isFutureTime ? end : start, {
          start: this.minRangeStart,
          end: this.maxRangeStart,
        });
      return {
        start,
        end,
        isToday: isSameDay(start, end) && isSameDay(start, new Date()),
        sameDay,
        sameWeek,
        isValidRange,
        isFuture: isFutureTime,
      };
    })
  );

  prev() {
    const viewDate = this.range.value;
    let days = differenceInDays(viewDate.end, viewDate.start).clampThis(0) + 1;
    return this.range.update(new DateRange<Date>(subDays(viewDate.start, days), subDays(viewDate.end, days)));
  }
  today() {
    const now = new Date();
    return this.range.update(new DateRange<Date>(startOfWeek(now), endOfWeek(now)));
  }
  next() {
    const viewDate = this.range.value;
    let days = differenceInDays(viewDate.end, viewDate.start).clampThis(0) + 1;
    return this.range.update(new DateRange<Date>(addDays(viewDate.start, days), addDays(viewDate.end, days)));
  }
  selectWeek(date: Date) {
    const start = startOfWeek(date),
      end = endOfWeek(date);
    return this.range.update(new DateRange<Date>(start, end));
  }
  async fetchBetween(start: Date, end: Date) {
    const user = this.userSettingsQuery.getValue();
    const isWork = (user.settings.tableDisplay as any) === 'ComeGo';
    if (isWork) {
      (this.comegoQuery.__store__ as ComegoStore).setLoading(true);
      const comego = await this.comegoService.getBetween(start, end, user.id).catch((err) => {
        pushError(err);
        return [];
      });
      (this.comegoQuery.__store__ as ComegoStore).upsertMany(comego);
      (this.comegoQuery.__store__ as ComegoStore).setLoading(false);
    } else {
      (this.myTimesQuery.__store__ as MyTimesStore).setLoading(true);
      const times = await firstValueFrom(
        this.myTimesService.adapter.get<Time[]>(
          environment.serverUrl +
            `/get/times?$filter=start ge '${start.toISOString()}' and start le '${end.toISOString()}' and user__id eq '${
              user.id
            }'`
        )
      ).catch((err) => {
        pushError(err);
        return [];
      });
      (this.myTimesQuery.__store__ as MyTimesStore).upsertMany(times);
      (this.myTimesQuery.__store__ as MyTimesStore).setLoading(false);
    }
  }
}
