import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ClientsQuery, ProjectsQuery } from 'timeghost-api';
const EntityMapper = {
  client: 'clientQuery',
  project: 'projectQuery',
};
@Pipe({
  name: 'defaultEntityTranslate',
})
export class DefaultEntityTranslatePipe implements PipeTransform {
  constructor(
    private projectQuery: ProjectsQuery,
    private clientQuery: ClientsQuery,
    private translate: TranslateService
  ) {}
  transform(
    _entity: { id: string; name: string; useAsDefault?: boolean },
    _entityName: 'project' | 'client',
    _translateKey: string,
    _defaultValue: string = null
  ): string {
    if (!_entity) return _defaultValue;
    if (_entity.useAsDefault ?? !!this[EntityMapper[_entityName]]?.getEntity(_entity.id)?.useAsDefault)
      return this.translate.instant(_translateKey);
    return _entity.name;
  }
}
