<div class="flex h-full min-h-0 flex-col space-y-4">
  <div class="mx-6 flex">
    <mat-form-field appearance="fill" class="mat-form-field-no-hint flex-auto">
      <mat-label>{{ 'utils.search' | translate }}</mat-label>
      <input
        matInput
        type="text"
        ignoreAutocomplete
        [formControl]="search"
        #searchElement
        cdkTrapFocus
        [cdkTrapFocusAutoCapture]="true"
      />
    </mat-form-field>
  </div>
  <div cdkVirtualScrollingElement class="scroll-y h-[500px] min-h-0">
    <ng-container *ngIf="entries$suggestions | async as suggestions">
      <ng-container *ngIf="suggestions.entries as entries">
        <div class="mx-4 mb-4 flex flex-row items-center justify-start gap-2 px-2">
          <mat-icon svgIcon="logoIcon" class="logo-icon"></mat-icon>
          <div class="flex flex-col">
            <h4 class="my-0" translate>utils.suggestions</h4>
            <span class="mat-hint" translate>buzz.ki-suggestions</span>
          </div>
        </div>
        <div class="mx-4 mb-4 flex flex-row flex-wrap items-center justify-start gap-2">
          @for (entry of entries; let last = $last; track entry.id) {
            <mat-card
              class="mat-card-button !mat-bg mb-2 cursor-pointer !rounded !px-3 !py-2"
              (click)="submitProject({ project: entry, task: entry.task })"
              (contextmenu)="openContextMenu($event, projectMenuTrigger, { entry: entry })"
              [class.border-active]="entry.selected"
              matTooltip
              [matTooltipOptions]="{ delay: [600, 0] }"
              [matTooltipTemplate]="projectPickerTooltip"
              [matTooltipTemplateContext]="{
                project: entry | projectProxy,
                tags: entry.tags,
              }"
            >
              <mat-card-content class="flex">
                <div class="truncate-flex flex flex-auto flex-col">
                  <div class="flex flex-row items-center justify-start gap-2">
                    <div
                      class="project-color-dot self-center"
                      *ngIf="entry.color as color"
                      [style.backgroundColor]="color"
                      [matTooltip]="'project.color' | translate"
                    ></div>
                    <h3 class="mb-0 truncate text-base font-bold">
                      {{ entry | defaultEntityTranslate: 'project' : 'project.none' | truncate: 80 }}
                    </h3>
                    <div class="mat-bg-30 h-1 w-1 rounded-full"></div>
                    <span class="flex-shrink-0 truncate">{{
                      entry.task?.name || ('task.none' | translate) | truncate: 40
                    }}</span>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          }
        </div>
      </ng-container>
      <div class="mx-4 -mt-2 mb-4 flex items-center justify-end">
        <button
          mat-button
          color="primary"
          class="link-button"
          *ngIf="suggestions.showMore !== undefined"
          (click)="showMore.value = !suggestions.showMore"
        >
          {{ (showMore.value ? 'utils.show_less' : 'utils.show_more') | translate }}
        </button>
      </div>
    </ng-container>
    <cdk-virtual-scroll-viewport [minBufferPx]="1600" [maxBufferPx]="4000" [itemSize]="48" class="overflow-y-auto">
      <div
        *cdkVirtualFor="let entry of entries$filtered; trackBy: trackId; let last = last; let first = first"
        class="flex px-4 py-2.5 mx-6 h-12 cursor-pointer text-md border-b-muted-foreground/40"
        elementRef
        #el="nativeElement"
        (click)="submitProject({ project: entry })"
        (contextmenu)="openContextMenu($event, projectMenuTrigger, { entry: entry })"
        [ngClass]="
          [
            first && last ? 'rounded-lg' : !last ? 'border-b' : first ? 'rounded-t-lg' : last ? 'rounded-b-lg' : null,
            first && !last && 'rounded-t-lg',
            entry.selected ? 'mat-bg-5' : 'bg-background/40',
          ] | clsx
        "
        matTooltip
        [matTooltipTemplate]="itemDescription"
        [matTooltipTemplateContext]="{ content: entry.description }"
        [matTooltipDisabled]="!entry.description"
        [matTooltipOptions]="{ delay: [500, 0], offset: [0, -10], stretchToTrigger: true }"
        [matTooltipPosition]="'bottom-start'"
      >
        <div class="flex flex-auto truncate items-center">
          <div class="truncate-flex flex flex-auto flex-col space-y-2">
            <div class="flex flex-row gap-2 truncate flex-shrink-0">
              <div class="flex items-center space-x-2 truncate">
                <div
                  class="size-4 rounded-full flex items-center justify-center border flex-shrink-0"
                  [ngClass]="entry.selected ? 'border-primary' : 'border-muted'"
                >
                  @if (entry.selected) {
                    <div class="bg-primary rounded-full size-2"></div>
                  } @else {
                    <div></div>
                  }
                </div>
                <h3 class="mb-0 truncate text-base font-semibold">
                  {{ entry | defaultEntityTranslate: 'project' : 'project.none' | truncate: 100 }}
                </h3>
              </div>
              <div class="!ml-auto flex flex-row items-center justify-start gap-2">
                @if (entry.color; as color) {
                  <div
                    class="size-5 flex items-center justify-center flex-col"
                    [matTooltip]="'project.color' | translate"
                  >
                    <div class="size-2.5 rounded-circle" [style.backgroundColor]="color"></div>
                  </div>
                }
                @if (entry.pinned) {
                  <mat-icon
                    class="bg-accent-2 rounded-circle !p-1 text-lg"
                    color="accent"
                    inline
                    [matTooltip]="'project.favourite' | translate"
                    >grade</mat-icon
                  >
                }
                @if (entry.billable) {
                  <mat-icon
                    class="bg-accent-2 rounded-circle flex-none !p-1 text-lg"
                    inline
                    color="accent"
                    [matTooltip]="'Billable_Alt' | translate"
                    >attach_money</mat-icon
                  >
                }
                @if (entry.private) {
                  <mat-icon
                    class="bg-primary/10 text-primary rounded-circle flex-none !p-1 text-lg"
                    inline
                    [matTooltip]="'project.private.state' | translate"
                    >lock</mat-icon
                  >
                }
                @if (entry.estimation > 0) {
                  <app-estimation-statistics-graph
                    [estimation]="entry.estimation || 0"
                    [recorded]="
                      entry.timesRecorded
                        ? (entry.timesRecorded.billable?.clamp(0, entry.timesRecorded.billable) || 0) +
                          (!entry.billable
                            ? entry.timesRecorded.notBillable?.clamp(0, entry.timesRecorded.notBillable) || 0
                            : 0)
                        : 0
                    "
                    [completed]="entry.completed"
                  ></app-estimation-statistics-graph>
                }
                <div class="text-xs badge badge-sm" [matTooltip]="'client.single' | translate">
                  <div class="text-xs">
                    {{ entry.client | defaultEntityTranslate: 'client' : 'client.none' | truncate: 100 }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          @if ((showNextItemIcon.value$ | async) !== false) {
            <div class="flex items-center justify-center self-start">
              <mat-icon>keyboard_arrow_right</mat-icon>
            </div>
          }
        </div>
      </div>
    </cdk-virtual-scroll-viewport>
    <!-- <div class="pb-48"></div> -->
  </div>
</div>
<mat-menu #projectContextMenu>
  <ng-template matMenuContent let-entry="entry">
    <div class="flex flex-col space-y-2">
      <button mat-menu-item (click)="this.togglePinProject(entry.id)">
        <mat-icon inline>star</mat-icon> {{ (entry.pinned ? 'project.fav-disable' : 'project.fav-enable') | translate }}
      </button>
      <a
        mat-menu-item
        [routerLink]="['/settings/projects', entry.id]"
        [disabled]="entry.useAsDefault"
        (click.prevent)="openProject(entry.id)"
      >
        <mat-icon inline>folder</mat-icon> {{ 'project.open' | translate }}
      </a>
    </div>
  </ng-template>
</mat-menu>
<div
  #projectMenuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="projectContextMenu"
  style="visibility: hidden; position: fixed"
  [style.left]="projectMenuPosition.x"
  [style.top]="projectMenuPosition.y"
></div>
<ng-template #projectPickerTooltip let-project="project" let-tags="tags">
  <div class="my-2 flex flex-col space-y-2 px-2.5 wflex-[1_0_220px]">
    <div class="space-y-1">
      <h3 class="mat-hint text-sm italic" translate>client.single</h3>
      <div class="font-semibold">{{ project?.client | defaultEntityTranslate: 'client' : 'client.none' }}</div>
    </div>
    <div class="space-y-1">
      <h3 class="mat-hint text-sm italic" translate>project.single</h3>
      <div class="font-semibold">{{ project | defaultEntityTranslate: 'project' : 'project.none' }}</div>
    </div>
    <div class="space-y-1" *ngIf="project?.description as desc">
      <h3 class="mat-hint text-sm italic" translate>Description</h3>
      <div>{{ desc | truncate: 120 }}</div>
    </div>
    <ng-container *ngIf="tags && tags.length > 0">
      <div class="space-y-1">
        <h3 class="mat-hint text-sm italic" translate>tag.plural</h3>
        <div class="flex flex-wrap gap-2">
          <div
            class="mbg-card pointer-events-none h-6 cursor-default select-none truncate rounded-full px-2.5 text-base leading-6"
            *ngFor="let item of tags; trackBy: trackId"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #itemDescription let-content="content">
  <div class="whitespace-pre">{{ content }}</div>
</ng-template>
