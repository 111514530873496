import { Pipe, PipeTransform } from '@angular/core';
const trimCommand = {
  left: 'trimStart',
  right: 'trimEnd',
};
@Pipe({
  name: 'trim',
  pure: true,
})
export class TrimPipe implements PipeTransform {
  transform(value: string, type?: 'left' | 'right'): string {
    return value?.[type ? trimCommand[type] : 'trim']?.();
  }
}
