<app-loader [isActive]="this.isLoading$ | async" [isAfterViewInit]="false" [enableCircle]="true"></app-loader>
<h1 matDialogTitle class="items-center justify-start gap-2">
  <button mat-icon-button *ngIf="hasStepper" (click)="prevStep()">
    <mat-icon>arrow_backward</mat-icon>
  </button>
  <div>{{ 'time.create' | translate }}</div>
  <div class="flex-auto"></div>
  <button mat-icon-button matDialogClose tabindex="-1">
    <mat-icon>close</mat-icon>
  </button>
</h1>
<mat-dialog-content cdkTrapFocus>
  <form (submit)="$event.preventDefault()" [formGroup]="this.group" *ngIf="this.group as grp">
    <div class="flex space-x-2">
      <div class="flex flex-auto flex-col items-stretch justify-stretch gap-2 lg:wflex-[1_0_440px]">
        <mat-form-field *ngIf="{ error: getErrorObservable('name') | async } as ctrl">
          <mat-label>{{ 'time.select' | translate }}</mat-label>
          <input type="text" matInput formControlName="name" cdkInitialFocus />
          <mat-hint *ngIf="(requireFields$ | async)?.name as fields">{{
            'errors.required' | translate: ({ field: 'time.name' } | translateArgs)
          }}</mat-hint>
          <mat-error *ngIf="ctrl.error as error">{{
            error.content | translate: (error.args | translateArgs)
          }}</mat-error>
        </mat-form-field>
        <div class="flex flex-col space-y-0">
          <mat-form-field
            (click)="$event.preventDefault(); this.openProjectPicker()"
            *ngIf="{ error: getErrorObservable('project') | async } as ctrl"
            class="cursor-pointer"
          >
            <mat-label>{{ 'project.select' | translate }}</mat-label>
            <input
              class="cursor-pointer"
              type="text"
              matInput
              readonly
              [isError]="group.controls.project.touched && ctrl.error"
              [ngModel]="
                group.value.project?.name
                  ? (group.value.project | defaultEntityTranslate: 'project' : 'project.none')
                  : null
              "
              [ngModelOptions]="{ standalone: true }"
            />
            <button
              *ngIf="group.value.project && this.defaultProject.id !== group.value.project.id"
              mat-icon-button
              matSuffix
              class="flex-shrink-0"
              type="button"
              (click)="$event.preventDefault(); $event.stopPropagation(); resetProject()"
            >
              <mat-icon>clear</mat-icon>
            </button>
            <mat-hint *ngIf="(requireFields$ | async)?.project as fields">{{
              'errors.record.project-req' | translate
            }}</mat-hint>
            <mat-error *ngIf="ctrl.error as error">{{ error.content | translate: error.args || {} }}</mat-error>
          </mat-form-field>
          <div
            *ngIf="(group.value.project | projectProxy)?.description as desc"
            class="mb-4 rounded bg-neutral-800/5 px-2.5 py-3 text-sm dark:bg-black/20"
          >
            {{ desc }}
          </div>
        </div>
        <ng-container *ngIf="group.value.project?.tags?.length">
          <div class="flex flex-wrap gap-2">
            <div
              class="mbg-card pointer-events-none h-6 cursor-default select-none rounded-full px-2.5 text-base leading-6"
              *ngFor="let item of group.value.project?.tags; trackBy: trackId"
            >
              {{ item.name }}
            </div>
          </div>
        </ng-container>
        <mat-form-field
          class="w-full"
          (click)="$event.preventDefault(); this.openTaskPicker()"
          *ngIf="{ error: getErrorObservable('task') | async } as ctrl"
          [class.cursor-pointer]="!taskDisabled"
        >
          <mat-label>{{ 'task.select' | translate }}</mat-label>
          <input
            [class.cursor-pointer]="!taskDisabled"
            type="text"
            matInput
            readonly
            [ngModel]="group.value.task?.name"
            [ngModelOptions]="{ standalone: true }"
            [isError]="group.controls.task.touched && ctrl.error"
            [disabled]="taskDisabled"
            #taskPickerInput
          />
          <button
            *ngIf="group.value.task"
            mat-icon-button
            matSuffix
            type="button"
            [disabled]="taskPickerInput.disabled"
            (click)="$event.preventDefault(); $event.stopPropagation(); group.patchValue({ task: null })"
          >
            <mat-icon>clear</mat-icon>
          </button>
          <mat-hint *ngIf="(requireFields$ | async)?.task as fields">{{
            'errors.record.desc-required' | translate
          }}</mat-hint>
          <mat-error *ngIf="ctrl.error as error">{{ error.content | translate: error.args || {} }}</mat-error>
        </mat-form-field>
        <div class="flex w-full cursor-pointer flex-col">
          <mat-form-field (click)="!$event.defaultPrevented && openTagsPicker()">
            <mat-label>{{ 'tag.select' | translate }}</mat-label>
            <input
              class="cursor-pointer"
              type="text"
              matInput
              readonly
              [value]="grp.value.tags | mapProp: 'name' | joinString: ', '"
            />
            <button
              *ngIf="grp.value.tags && grp.value.tags.length > 0"
              mat-icon-button
              matSuffix
              type="button"
              (click.native)="grp.controls.tags.setValue([])"
            >
              <mat-icon>clear</mat-icon>
            </button>
            <mat-error *ngIf="!grp.value.tags"
              >{{ 'errors.required' | translate: { field: 'Tags' | translate } }} *</mat-error
            >
          </mat-form-field>
        </div>
        <div
          class="flex w-full cursor-pointer flex-col"
          (click)="$event.preventDefault(); openUserPicker()"
          *ngIf="workspace$isAdmin.value$ | async"
        >
          <mat-form-field class="form-control-interactive">
            <mat-label>{{ 'user.select' | translate }}</mat-label>
            <div *ngIf="group.value.user as user" class="-mb-16px flex items-center space-x-1.5">
              <app-user-avatar size="40px" class="rounded-full" [userId]="user.id"></app-user-avatar>
              <div class="flex flex-auto flex-col leading-none">
                <span>{{ user.name }}</span>
                <span class="mat-hint text-sm">{{ user.email }}</span>
              </div>
              <button
                *ngIf="postDiffUser$ | async"
                mat-icon-button
                (click)="$event.preventDefault(); $event.stopPropagation(); patchValue({ user: null })"
              >
                <mat-icon>clear</mat-icon>
              </button>
            </div>
            <input matInput [value]="group.value.user" readonly class="!pointer-events-none" />
          </mat-form-field>
        </div>
        <div class="flex w-full cursor-pointer flex-col" (click)="$event.preventDefault(); openCalPicker()">
          <mat-form-field class="pointer-events-none">
            <mat-label>{{ 'timer.date' | translate }}</mat-label>
            <input matInput [value]="grp.value.date | formatDate" class="!pointer-events-none" />
          </mat-form-field>
        </div>
        <div class="flex items-center space-x-2">
          <ng-container
            *ngTemplateOutlet="
              mode === 'range'
                ? rangeModeTemplate
                : mode === 'range_optional'
                  ? stateVisibleMode
                    ? rangeModeTemplate
                    : durationModeTemplate
                  : mode === 'duration'
                    ? durationModeTemplate
                    : null
            "
          ></ng-container>
          <ng-container *ngIf="mode === 'range_optional'">
            <button
              mat-icon-button
              [matTooltip]="'timer.time.changeInputMode' | translate"
              [matTooltipOptions]="{ hideOnClick: false }"
              (click)="stateVisibleMode = !stateVisibleMode"
              type="button"
            >
              <mat-icon>swap_horiz</mat-icon>
            </button>
          </ng-container>
        </div>
        <mat-checkbox formControlName="billable" class="mb-4" color="primary">{{
          'Billable_Alt' | translate
        }}</mat-checkbox>
      </div>
      <div fxHide.lt-md class="events-container relative flex flex-col md:wflex-[1_0_auto]" tabindex="-1">
        <mwl-calendar-day-view
          id="cal-day-view"
          [events]="this.events$ | async"
          [viewDate]="this.group.value.date"
          tabindex="-1"
          [hourSegments]="4"
          [hourSegmentHeight]="30"
          [hourSegmentTemplate]="weekViewHourSegmentTemplate"
          class="scroll-y mb-2 h-[500px] max-h-full"
          [eventTemplate]="eventTemplate"
          (eventTimesChanged)="onCalChange($event)"
        >
        </mwl-calendar-day-view>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="mat-action-filled">
  <button
    mat-flat-button
    class="mat-action-filled"
    color="primary"
    (click)="this.addEntry()"
    [disabled]="this.group.invalid"
  >
    {{ this.i18n?.submit || 'time.create' | translate }}
  </button>
</mat-dialog-actions>
<mat-menu #timeContextMenu="matMenu">
  <ng-template matMenuContent let-data>
    <button mat-menu-item (click)="setToNow(data.prop)">{{ 'timeContextMenu.setTo.now' | translate }}</button>
    <button mat-menu-item [matMenuTriggerFor]="timeContextMenuTimeTemplates" [matMenuTriggerData]="{ $implicit: data }">
      {{ 'timeContextMenu.setToMore' | translate }}
    </button>
  </ng-template>
</mat-menu>
<mat-menu #timeContextMenuTimeTemplates="matMenu">
  <ng-template matMenuContent let-data>
    <button mat-menu-item (click)="setMinutesDiff(5, data.prop)">
      {{ 'timeContextMenu.setTo.MinDiffs' | translate: { value: 5 } }}
    </button>
    <button mat-menu-item (click)="setMinutesDiff(10, data.prop)">
      {{ 'timeContextMenu.setTo.MinDiffs' | translate: { value: 10 } }}
    </button>
    <button mat-menu-item (click)="setMinutesDiff(15, data.prop)">
      {{ 'timeContextMenu.setTo.MinDiffs' | translate: { value: 15 } }}
    </button>
    <button mat-menu-item (click)="setMinutesDiff(30, data.prop)">
      {{ 'timeContextMenu.setTo.MinDiffs' | translate: { value: 30 } }}
    </button>
    <button mat-menu-item (click)="setHourDiff(1, data.prop)">
      {{ 'timeContextMenu.setTo.HrDiff' | translate: { value: 1 } }}
    </button>
    <button mat-menu-item (click)="setHourDiff(2, data.prop)">
      {{ 'timeContextMenu.setTo.HrDiffs' | translate: { value: 2 } }}
    </button>
    <button mat-menu-item (click)="setHourDiff(4, data.prop)">
      {{ 'timeContextMenu.setTo.HrDiffs' | translate: { value: 4 } }}
    </button>
    <button mat-menu-item (click)="setWorkDay()">{{ 'timeContextMenu.setTo.1workDay' | translate }}</button>
  </ng-template>
</mat-menu>
<div
  style="visibility: hidden; position: fixed"
  [style.left]="timeContextMenuPosition.x"
  [style.top]="timeContextMenuPosition.y"
  #timeContextMenuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="timeContextMenu"
></div>
<ng-template
  #weekViewHourSegmentTemplate
  let-segment="segment"
  let-locale="locale"
  let-segmentHeight="segmentHeight"
  let-isTimeLabel="isTimeLabel"
>
  <div
    #segmentElement
    class="cal-hour-segment"
    [style.height.px]="segmentHeight"
    [class.cal-hour-start]="segment.isStart"
    [class.cal-after-hour-start]="!segment.isStart"
    [ngClass]="segment.cssClass"
  >
    <div class="cal-time" *ngIf="isTimeLabel && (this.currentFormat$ | async) as format">
      {{ segment.date | formatDate: 'time' }}
    </div>
  </div>
</ng-template>
<ng-template #rangeModeTemplate>
  <ng-container [formGroup]="group" *ngIf="{ error: groupErrors } as ctrl">
    <div class="flex flex-1 flex-col">
      <div class="flex flex-row items-center justify-between">
        <app-time-input
          [inputPlaceholder]="'timer.time.start' | translate"
          matInput
          formControlName="start"
          [displayFormat]="false"
          #tStartFormField
          [maxlength]="5"
          elementRef
          #startInpRef="nativeElement"
          (focus)="this.selectInput($event, startInpRef)"
          (keydown.enter)="$event.preventDefault()"
          (contextmenu)="
            this.onTimeContextMenuTrigger($event, {
              time: this.group.value.start,
              prop: 'start',
            })
          "
          (click)="$event.stopPropagation(); this.selectInput($event, startInpRef)"
          class="wflex-[0_0_74px]"
        >
          <ng-template timeInputControl let-control="control" let-inputPlaceholder="inputPlaceholder">
            <mat-form-field fxFill>
              <mat-label translate>timer.time.start</mat-label>
              <input
                type="text"
                matInput
                [placeholder]="inputPlaceholder"
                [formControl]="control"
                [isError]="(group.errors?.same && group.errors) || group.errors?.range"
              />
            </mat-form-field>
          </ng-template>
        </app-time-input>
        <span class="tg-seperator mx-2">
          <mat-icon>arrow_forward</mat-icon>
        </span>
        <app-time-input
          [inputPlaceholder]="'timer.time.end' | translate"
          matInput
          formControlName="end"
          [displayFormat]="false"
          #tEndFormField
          [maxlength]="5"
          elementRef
          #endInpRef="nativeElement"
          (focus)="this.selectInput($event, endInpRef)"
          (keydown.enter)="$event.preventDefault()"
          (contextmenu)="
            this.onTimeContextMenuTrigger($event, {
              time: this.group.value.end,
              prop: 'end',
            })
          "
          (click)="$event.stopPropagation(); this.selectInput($event, endInpRef)"
          class="wflex-[0_0_74px]"
        >
          <ng-template timeInputControl let-control="control" let-inputPlaceholder="inputPlaceholder">
            <mat-form-field fxFill>
              <mat-label translate>timer.time.end</mat-label>
              <input
                type="text"
                matInput
                [placeholder]="inputPlaceholder"
                [formControl]="control"
                [isError]="(group.errors?.same && group.errors) || group.errors?.range"
              />
            </mat-form-field>
          </ng-template>
        </app-time-input>
      </div>
      <div class="mat-error mat-typography -mt-4 text-sm" *ngIf="ctrl.error as err">{{ err | translate }}</div>
    </div>
  </ng-container>
</ng-template>
<ng-template #durationModeTemplate>
  <div class="flex flex-1 flex-col">
    <ng-container [formGroup]="group">
      <tg-duration-input-control formControlName="duration" displayFormat="HH:mm">
        <ng-template durationInputControl let-control="control" let-inputPlaceholder="inputPlaceholder" let-name="name">
          <mat-form-field fxFill>
            <mat-label translate>timer.time.duration</mat-label>
            <input type="text" matInput [formControl]="control" />
          </mat-form-field>
        </ng-template>
      </tg-duration-input-control>
    </ng-container>
  </div>
</ng-template>
<ng-template
  #eventTemplate
  let-weekEvent="weekEvent"
  let-tooltipPlacement="tooltipPlacement"
  let-eventClicked="eventClicked"
  let-tooltipTemplate="tooltipTemplate"
  let-tooltipAppendToBody="tooltipAppendToBody"
  let-tooltipDisabled="tooltipDisabled"
  let-tooltipDelay="tooltipDelay"
  let-column="column"
  let-daysInWeek="daysInWeek"
>
  <div
    class="cal-event"
    [ngStyle]="{
      backgroundColor: weekEvent.event.color?.secondary,
      borderColor: weekEvent.event.color?.primary,
    }"
    [mwlCalendarTooltip]="
      !tooltipDisabled
        ? (weekEvent.event.title
          | calendarEventTitle
            : (daysInWeek === 1 ? 'dayTooltip' : 'weekTooltip')
            : weekEvent.tempEvent || weekEvent.event)
        : ''
    "
    [tooltipPlacement]="tooltipPlacement"
    [tooltipEvent]="weekEvent.tempEvent || weekEvent.event"
    [tooltipTemplate]="tooltipTemplate"
    [tooltipAppendToBody]="tooltipAppendToBody"
    [tooltipDelay]="tooltipDelay"
    (mwlClick)="eventClicked.emit({ sourceEvent: $event })"
    (mwlKeydownEnter)="eventClicked.emit({ sourceEvent: $event })"
    tabindex="-1"
    role="application"
    [attr.aria-label]="
      { event: weekEvent.tempEvent || weekEvent.event, locale: locale } | calendarA11y: 'eventDescription'
    "
  >
    <mwl-calendar-event-actions
      [event]="weekEvent.tempEvent || weekEvent.event"
      [customTemplate]="eventActionsTemplate"
    >
    </mwl-calendar-event-actions>
    &ngsp;
    <mwl-calendar-event-title
      [event]="weekEvent.tempEvent || weekEvent.event"
      [customTemplate]="eventTitleTemplate"
      [view]="daysInWeek === 1 ? 'day' : 'week'"
    >
    </mwl-calendar-event-title>
  </div>
</ng-template>
