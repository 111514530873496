import { firstValueFrom, Observable } from 'rxjs';

type TaskPromise<T> = () => Promise<T>;
export default async function queuePromise<T = any>(promises: TaskPromise<T>[]) {
  let data: T[] = [];
  return await promises.reduce((acc, item) => {
    return acc.then((data) =>
      item().then((v) => {
        data.push(v);
        return data;
      })
    );
  }, Promise.resolve(<typeof data>[]));
}
export async function queuePromiseStack<T = any>(promises: TaskPromise<T>[], stackSize: number = 100) {
  const queueStack = chunk(promises, stackSize).map((promiseChunk) => () => Promise.all(promiseChunk.map((p) => p())));
  return await queuePromise(queueStack).then((data) =>
    data.reduce((acc, r) => {
      if (r?.length) acc.push(...r);
      return acc;
    }, <T[]>[])
  );
}

export function chunk<T>(arr: T[], size: number) {
  return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size));
}
export function toPromise<T = any>(obs: Observable<T>) {
  return firstValueFrom(obs);
}
type UseAsyncResponse<T = any> = { error?: any; data: T; ok: boolean };
export async function useAsync<T>(promise: () => Promise<T>): Promise<UseAsyncResponse<T>> {
  try {
    const data = await promise();
    return { data, ok: true };
  } catch (ex: any) {
    return { data: null, ok: false, error: ex };
  }
}
