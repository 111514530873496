import { InjectionToken } from '@angular/core';
import { ExternalToast } from 'ngx-sonner';

export const NGX_SONNER_CONFIG = new InjectionToken<ExternalToast>('Sonner Config', {
  providedIn: 'root',
  factory() {
    return SONNER_DEFAULT_CONFIG;
  },
});
export const SONNER_DEFAULT_CONFIG: ExternalToast = {
  duration: 10000,
  dismissible: true,
  closeButton: true,
};
