import { Component, OnInit, Inject, ViewContainerRef, HostListener, OnDestroy } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
  MatLegacyDialog as MatDialog,
} from '@angular/material/legacy-dialog';
import { DialogAnchor } from '../client-project-picker-dialog/client-project-picker-dialog.component';
import { Tag, TagsService, TagType, Logger, TagsQuery, UserSettingsQuery } from 'timeghost-api';
import {
  distinctUntilChanged,
  filter,
  startWith,
  map,
  debounceTime,
  finalize,
  catchError,
  auditTime,
} from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, Subject, throwError } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { entitiesToArray } from '@datorama/akita';
import { CreateTagComponent } from '../create-tag/create-tag.component';
import { debounceTimeAfterFirst } from '@app/_helpers/debounceAfterTime';
import { hasPermission } from '@app/_helpers/utils';
import { xorBy } from 'lodash-es';
const UNIQ_ITEMS = <T>(uniq: T[], item: T, byProp: string) =>
  (byProp ? uniq.findIndex((y) => y[byProp] === item[byProp]) !== -1 : uniq.includes(item)) ? uniq : [...uniq, item];
const log = new Logger('TagPickerDialogComponent');
@Component({
  selector: 'app-tag-picker-dialog',
  templateUrl: './tag-picker-dialog.component.html',
  styleUrls: ['./tag-picker-dialog.component.scss'],
})
export class TagPickerDialogComponent implements OnInit, OnDestroy {
  private ondestroy$ = new Subject<void>();
  ngOnDestroy() {
    this.ondestroy$.next();
    this.ondestroy$.complete();
  }
  private _isLoading = new BehaviorSubject<boolean>(true);
  readonly isLoading$ = this._isLoading.asObservable().pipe(distinctUntilChanged());
  get isLoading() {
    return this._isLoading.getValue();
  }
  set isLoading(val: boolean) {
    this._isLoading.next(val);
  }
  readonly workspace$createPermission = this.userSettingsQuery.select().pipe(
    map((userSettings) => {
      return userSettings.workspace.permissionSettings?.groupsCanManageTags?.find((x) =>
        hasPermission(x.id, userSettings),
      );
    }),
  );
  searchinput = new UntypedFormControl('', [
    (ctrl) => {
      return () => {
        const { entities } = this.tagsQuery.getValue();
        return (ctrl.value as string)?.trim().length > 0
          ? entitiesToArray(entities, {}).filter((x: Tag) => x.name === ctrl.value).length > 0
            ? { errors: { tags: ['tags.exists'] } }
            : null
          : null;
      };
    },
  ]);
  private _selectedTags = new BehaviorSubject<Tag[]>(null);
  readonly selectedTags$ = this._selectedTags.asObservable().pipe(distinctUntilChanged());
  readonly selected$change = this.selectedTags$.pipe(
    map((tags) => {
      return xorBy(tags, this.src.data.SelectedTags ?? [], (x) => x.id).length > 0;
    }),
  );
  get selectedTags() {
    return this._selectedTags.getValue();
  }
  set selectedTags(val: Tag[]) {
    this._selectedTags.next(val.uniqBy((x) => x.id));
  }
  constructor(
    @Inject(MAT_DIALOG_DATA)
    private src: TagDialogData,
    private ref: MatDialogRef<TagPickerDialogComponent>,
    private view: ViewContainerRef,
    private dialog: MatDialog,
    private tagService: TagsService,
    private tagsQuery: TagsQuery,
    private userSettingsQuery: UserSettingsQuery,
  ) {}
  get tagType() {
    return this.src.data?.type;
  }
  data$filtered = combineLatest([
    this.tagsQuery.selectAll({ filterBy: (x) => x.tagType === this.tagType }),
    this.selectedTags$,
    (this.searchinput.valueChanges as Observable<string>).pipe(
      startWith(''),
      distinctUntilChanged(),
      debounceTime(200),
    ),
  ]).pipe(
    map(([tags, selected, search]): [Tag[], string] => [
      tags.map((x) => ({ ...x, selected: !!selected.find((y) => y.id === x.id) })),
      search,
    ]),
    map(([tags, search]) => {
      return search && search.trim().length > 0
        ? tags.filter((x) => x.name && x.name.toLowerCase().indexOf(search.toLowerCase()) > -1)
        : tags;
    }),
  );

  selectItem(tag: Tag) {
    const tags = this.selectedTags.filter((x) => x.id === tag.id);
    if (this.src.data.canToggle && tags.length > 0) {
      this.selectedTags = this.selectedTags.filter((x) => x.id !== tag.id);
      return;
    }
    this.selectedTags = [...this.selectedTags, tag];
  }

  trackFiltered(obj: Tag) {
    return obj.id;
  }
  onCreateTag(ev: KeyboardEvent) {
    if (ev.ctrlKey && ev.code === 'Enter') {
      this.isLoading = true;
      this.searchinput.disable();
      this.tagService
        .add({
          name: this.searchinput.value,
          tagType: this.tagType,
        })
        .pipe(
          catchError((err) => {
            return throwError(err);
          }),
          finalize(() => {
            this.searchinput.enable();
          }),
        )
        .subscribe(
          (x) => {
            this.selectItem(x);
            this.isLoading = false;
            this.searchinput.enable();
          },
          (err) => {
            log.error('Error while processing request', err);
            this.isLoading = false;
            this.searchinput.enable();
          },
        );
    }
  }
  ngOnInit() {
    this.selectedTags = this.src.data.SelectedTags || [];
    this.ref._containerInstance._config.autoFocus = true;
    this.ref.addPanelClass('tag-picker-dialog-container');
    this.ref.updateSize('400px');
    this.ref.disableClose = true;
    this.ref
      .keydownEvents()
      .pipe(filter((x) => ['Escape'].includes(x.key)))
      .subscribe(() => this.closeDialog());
    this.ref.backdropClick().subscribe(() => this.closeDialog());
    this.tagsQuery
      .selectLoading()
      .pipe(distinctUntilChanged(), debounceTimeAfterFirst(100))
      .subscribe((x) => (this.isLoading = x));
  }
  closeDialog() {
    return this.ref.close(this.selectedTags?.map((x) => ({ ...x, tagType: x.tagType ?? this.tagType })) || []);
  }
  createTagDialog() {
    return this.dialog
      .open(CreateTagComponent, {
        data: {
          name: this.searchinput.value,
          type: this.tagType,
          allowTypeModify: this.src.data.allowTypeModify,
        },
      })
      .afterClosed()
      .pipe(filter((x) => !!x))
      .subscribe((x) => {
        this.selectItem(x);
      });
  }
}

export interface TagDialogData {
  anchor: DialogAnchor;
  data: {
    SelectedTags: Tag[];
    type: TagType;
    canToggle: boolean;
    allowTypeModify?: boolean;
  };
}
