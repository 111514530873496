export const DURATION_REGEX = /(\d+)?:?(\d{2})?$/;
export const parseDuration = (timeStr: any): [number, number] =>
  typeof timeStr === 'number'
    ? [Math.ceil((timeStr - (timeStr % 60)) / 60), Math.floor(timeStr % 60)]
    : (`${timeStr}`
        .padStart(2, '0')
        .match(DURATION_REGEX)
        .slice(1)
        .map((x) => (x !== undefined ? ~~Number.parseInt(x, 10) : x)) as [number, number]);
export const parseDurationSeconds = (timeStr: any) => (([h, m]) => ~~h * 60 + ~~m)(parseDuration(timeStr)) * 60;
export const parseDurationAsFormat = (timeStr: any) =>
  (([hours, minutes]) => `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`)(
    parseDuration(timeStr)
  );
