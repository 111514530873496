import { Injectable } from '@angular/core';
import { createRxValue } from '@app/_helpers/utils';
import { AppService } from '@app/app.service';
import { WIDGET_ID as FRILL_WIDGET_ID, initFrillJs } from '@env/frill';
import { differenceInMinutes } from 'date-fns/esm';
import { Logger, UserService, UserSettingsQuery } from 'timeghost-api';
interface FrillSSOToken {
  token: string;
  created: Date;
}
interface FrillInstance {
  toggle: () => void;
  close: () => void;
  open: () => void;
}
export class FrillTimeoutError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'frill.timeout';
  }
}
export class FrillError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'frill.error';
  }
}
const log = new Logger('FrillButton');
@Injectable({
  providedIn: 'root',
})
export class FrillButtonService {
  constructor(
    private appService: AppService,
    private userSettingsQuery: UserSettingsQuery,
    private userService: UserService,
  ) {}
  readonly frillLoading = createRxValue(false);
  readonly frillBadgeCount = createRxValue<number>();
  readonly frillErrorState = createRxValue<any>(null);
  private frillInstance: FrillInstance;
  private _ssoToken: FrillSSOToken;
  private _config: any;
  async getFrillWidget(): Promise<FrillInstance> {
    const theme = await this.appService.getCurrentTheme();
    const user = this.userSettingsQuery.getValue();
    const isAdmin = !!user.workspace.users.find((x) => x.admin && x.id === user.id);
    const ssoToken =
      !this._ssoToken || differenceInMinutes(new Date(), this._ssoToken.created) >= 30
        ? await this.userService
            .getFrillSsoToken()
            .toPromise()
            .then((x) => (this._ssoToken = { token: x?.ssoToken, created: new Date() }))
            .then((x) => x.token)
            .catch(() => null)
        : this._ssoToken.token;
    const getInstance = () => {
      this._config = {
        theme,
        ssoToken: ssoToken,
        onUpdate: (ev: string, data: any) => {
          if (ev === 'badgeCount') this.frillBadgeCount.value = data;
        },
      };
      return initFrillJs(isAdmin ? FRILL_WIDGET_ID.ADMIN : FRILL_WIDGET_ID.NORMAL, this._config);
    };
    if (this.frillInstance) {
      this.frillLoading.value = false;
      return this.frillInstance;
    }
    this.frillLoading.value = true;
    return await getInstance()
      .then((Frill: any) => {
        this.frillLoading.update(false);
        this.frillInstance = Frill;
        return this.frillInstance;
      })
      .catch((err) => {
        this.frillLoading.update(false);
        log.error(err);
        if (err instanceof Error && err.name.slice(0, err.name.indexOf('.')) === 'frill')
          this.frillErrorState.update(err);
        return null;
      });
  }
}
