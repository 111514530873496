import { Project, ProjectPermissionRole as PermissionRole, UserSettings, WhoCanAddTasks } from 'timeghost-api';

export default function (
  userSettings: UserSettings,
  project: Project,
  options?: { workspaceAdmin?: boolean; projectManager?: boolean; author?: boolean }
) {
  return (
    (options?.author ? project['author'] === userSettings.id : false) ||
    !!userSettings.workspace.users?.find(
      (u) =>
        u.id === userSettings.id &&
        (options?.workspaceAdmin !== undefined ? !!options?.workspaceAdmin === u.admin : true)
    ) ||
    !!project.groups
      ?.map((group) =>
        userSettings.workspace.groups
          .find((g) => g.id === group.id)
          ?.users?.map((x) => ({ id: x.id, role: group.role }))
      )
      .filter((x) => !!x)
      .reduce((l, r) => [...l, ...r], [])
      .concat(project.users || [])
      .find(
        (u) =>
          u.id === userSettings.id &&
          (options?.projectManager !== undefined ? !!options?.projectManager === (u.role === 1) : true)
      )
  );
}
export function userProjectTaskPerm(userSettings: UserSettings, project: Project) {
  const perm = project.whoCanAddTasks;
  if (
    perm === WhoCanAddTasks.everyone ||
    !!userSettings.workspace.users?.find((u) => u.id === userSettings.id && u.admin)
  )
    return true;
  const permUser = project.groups
    ?.map((group) =>
      userSettings.workspace.groups.find((g) => g.id === group.id)?.users?.map((x) => ({ id: x.id, role: group.role }))
    )
    .filter((x) => !!x)
    .reduce((l, r) => [...l, ...r], [])
    .concat(project.users || [])
    .filter((u) => u.id === userSettings.id);
  const permArr = {
    [WhoCanAddTasks.pm_only]: [PermissionRole.manager],
    [WhoCanAddTasks.project_team]: [PermissionRole.manager, PermissionRole.none],
  };
  return !!permUser?.find((p) => !!permArr[perm]?.includes(p.role));
}

export function findRolePerm(user: Project['users'][0]) {
  return Object.entries(WhoCanAddTasks).find(
    ([, val]) => val.valueOf() === user.role
  )?.[0] as keyof typeof WhoCanAddTasks;
}
