import { AfterContentInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { ElRefDirective } from '@app/_directives/el-ref/el-ref.directive';
import { clampDay } from '@app/_helpers/date-fns';
import { CustomValidators } from '@app/_validators/custom-validators';
import { TaskPickerDialogComponent } from '@app/components/task-picker-dialog/task-picker-dialog.component';
import { CalendarEvent } from 'calendar-utils';
import {
  addMinutes,
  addSeconds,
  endOfDay,
  format as formatString,
  intervalToDuration,
  isValid,
  parse as parseFromString,
  roundToNearestMinutes,
  setSeconds,
  startOfDay,
} from 'date-fns/esm';
import { addHours, parse as parsefp, subHours, subMinutes } from 'date-fns/esm/fp';
import { flow } from 'lodash';
import { MediaObserver } from 'ngx-flexible-layout';
import { BehaviorSubject, combineLatest, firstValueFrom } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import {
  Logger,
  MyTimesQuery,
  MyTimesService,
  Project,
  ProjectsQuery,
  Tag,
  TagType,
  Task,
  Time,
  UserSettingsQuery,
  Workspace,
} from 'timeghost-api';

import { ErrorStateMatcher } from '@angular/material/core';
import { ObserveFormGroupErrors, useFormErrorObservable } from '@app/_helpers/get-error-observable';
import { pushError } from '@app/_helpers/globalErrorHandler';
import parseSubscriptionAsStatus from '@app/_helpers/parseSubscriptionAsStatus';
import {
  DEFAULT_PERMISSION_GROUPS,
  createRxValue,
  fromRxValue,
  hasPermission,
  splitLongRangeToDay,
} from '@app/_helpers/utils';
import { MaterialScaleVariable } from '@app/animations/fade';
import {
  parseDurationAsFormat,
  parseDurationSeconds,
} from '@app/components/duration-input-control/duration-input-utils';
import {
  UserSinglePickerDialogComponent,
  UserSinglePickerDialogData,
} from '@app/components/user-single-picker-dialog/user-single-picker-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import produce from 'immer';
import { MyTimesStore } from 'timeghost-api/lib/stores/myTimes/myTimes.store';
import {
  ClientProjectDialogData,
  ClientProjectPickerDialogComponent,
} from '../dialogs/client-project-picker-dialog/client-project-picker-dialog.component';
import { TagDialogData, TagPickerDialogComponent } from '../dialogs/tag-picker-dialog/tag-picker-dialog.component';
import { RecordToolbarService } from '../record-toolbar/record-toolbar.service';
import { TimeDatePickerConfig } from '../time-date-picker/time-date-picker-config';
import { TimeDatePickerComponent } from '../time-date-picker/time-date-picker.component';

const log = new Logger('TimeTrackerCalendarUpdateDialogComponent');
class CustomFieldErrorStateMatcher implements ErrorStateMatcher {
  constructor(private ctrl: FormControl) {}
  isErrorState: ErrorStateMatcher['isErrorState'] = (control, form) => {
    return this.ctrl && this.ctrl.invalid && (this.ctrl.dirty || this.ctrl.touched);
  };
}
interface SplitTime {
  start: Date;
  end: Date;
  selected: boolean;
  format: string;
}
@ObserveFormGroupErrors()
@Component({
  selector: 'app-time-tracker-calendar-update-dialog',
  templateUrl: './time-tracker-calendar-update-dialog.component.html',
  styleUrls: ['./time-tracker-calendar-update-dialog.component.scss'],
  animations: [MaterialScaleVariable(0.875)],
})
export class TimeTrackerCalendarUpdateDialogComponent implements OnInit, AfterContentInit {
  private _isLoading = new BehaviorSubject<boolean>(false);
  readonly isLoading$ = this._isLoading.asObservable().pipe(distinctUntilChanged());
  get isLoading() {
    return this._isLoading.getValue();
  }
  set isLoading(val: boolean) {
    this._isLoading.next(val);
  }
  group = new FormGroup(
    {
      name: new FormControl('', [
        CustomValidators.minLength(3),
        CustomValidators.entityValidator(
          () => this.userSettingsQuery.getValue(),
          (user, ctrl) => {
            if (user.workspace?.settings?.requireName && !ctrl.value) {
              return {
                required: true,
              };
            }
            return null;
          },
        ),
      ]),
      project: new FormControl<any>(null, [
        (ctrl) => {
          const user = this.userSettingsQuery.getValue();
          if (user.workspace?.settings?.requireProject) {
            const projectValue = ctrl.value as Project;
            if (!projectValue || projectValue.useAsDefault)
              return {
                required: true,
              };
          }
          return null;
        },
      ]),
      task: new FormControl(null, [
        (ctrl) => {
          const user = this.userSettingsQuery.getValue();
          if (user.workspace?.settings?.requireTask) {
            if (!ctrl.value?.id)
              return {
                required: true,
              };
          }
          return null;
        },
      ]),
      billable: new FormControl(false),
      date: new FormControl(null, [Validators.required]),
      start: new FormControl(null),
      end: new FormControl(null, (ctrl) => {
        if (!this.isRecording) return Validators.required(ctrl);
        return null;
      }),
      duration: new FormControl(null),
      tags: new FormControl<Tag[]>([]),
      user: new FormControl<Workspace['users'][0]>(null),
    },
    { updateOn: 'change', validators: [] },
  );
  defaultProject: Project;
  settings$allowFutureTimeTracking = this.userSettingsQuery
    .select((x) => x.workspace.settings)
    .pipe(map((x) => (x.allowFutureTimeTracking ? null : new Date())));
  constructor(
    private ref: MatDialogRef<TimeTrackerCalendarUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: CalendarEvent<{ time: Time; oldStart?: Date; oldEnd?: Date; forceUpdate?: boolean }>,
    private myTimes: MyTimesService,
    private myTimesQuery: MyTimesQuery,
    private projectsQuery: ProjectsQuery,
    private media: MediaObserver,
    private userSettingsQuery: UserSettingsQuery,
    private recordService: RecordToolbarService,
    private dialog: MatDialog,
    private translate: TranslateService,
  ) {
    this.defaultProject = this.projectsQuery.getAll().find((x) => x.useAsDefault === true);
  }
  ngAfterContentInit(): void {
    this.group.controls['project'].markAsDirty(), this.group.controls['task'].markAsDirty();
  }
  get isRecording() {
    const time = this.data.meta.time;
    return time && !time?.end;
  }
  get requireSplit() {
    const start = this.data.start,
      now = new Date();
    return this.isRecording && start.getDate() !== now.getDate();
  }
  readonly splitTimes = createRxValue<SplitTime[]>();
  readonly splitTimes$ = this.splitTimes.asObservable();
  readonly splitTimes$valid = this.splitTimes$.pipe(
    map((times) => {
      if (!times) return true;
      if (!times.find((d) => d.selected)) return false;
      return true;
    }),
  );
  toggleSplitTime(index: number) {
    this.splitTimes.update((items) => {
      items.splice(index, 1, { ...items[index], selected: !items[index].selected });
      return items;
    }, true);
  }
  readonly workspace$isAdmin = fromRxValue(
    this.userSettingsQuery.select((x) => hasPermission(DEFAULT_PERMISSION_GROUPS.Admin, x)),
  );
  readonly postDiffUser$ = combineLatest([
    this.group.valueChanges.pipe(
      map((x) => x?.user?.id),
      distinctUntilChanged(),
    ),
    this.userSettingsQuery.select(),
  ]).pipe(map(([uid, user]) => uid && uid !== user.id));
  get forceUpdate() {
    return this.data?.meta?.forceUpdate;
  }
  get isMobile() {
    return this.media.isActive(['xs', 'sm']);
  }
  get timeZone() {
    return this.userSettingsQuery.getValue().settings.timeZone;
  }
  get mode() {
    return this.userSettingsQuery.getValue()?.workspace.settings?.timesMode;
  }
  private _stateVisibleMode = this.recordService.group.value?.inputMode === 'range';
  get stateVisibleMode() {
    return this._stateVisibleMode;
  }
  set stateVisibleMode(v: boolean) {
    if (this.userSettingsQuery.getValue().workspace?.settings?.timesMode === 'range_optional') {
      const inputMode = v ? 'range' : 'duration';
      this.recordService.group.patchValue({
        inputMode,
      });
    }
    this._stateVisibleMode = v;
  }
  errorStateMatcher(ctrlName: string) {
    return new CustomFieldErrorStateMatcher(this.group.controls[ctrlName]);
  }
  getErrorObservable(controlName: string) {
    const aliasName =
      {
        name: 'time.name',
      }[controlName] || controlName;
    return useFormErrorObservable(this)(
      controlName,
      () => this.group.controls[controlName],
      {
        required: (error, ctrl) => {
          if (controlName === 'task') return { content: 'errors.record.desc-required', args: {} };
          if (controlName === 'project') return { content: 'errors.record.project-req', args: {} };
          return {
            content: 'errors.required',
            args: { field: aliasName },
          };
        },
        minlength: (error, ctrl) => ({
          content: 'errors.minlength',
          args: { field: aliasName, length: error.requiredLength },
        }),
      },
      (key) => key,
      {
        initialValidate: true,
      },
    );
  }
  refreshGroup(data: typeof this.data) {
    const user = this.userSettingsQuery.getValue();
    const timeUser = data.meta.time?.user || user.workspace.users.find((x) => x.id === user.id);
    const mode = this.mode;
    const start = data.start,
      end = data.end;
    this.patchValue(
      {
        name: data.title,
        start: formatString(start, 'HH:mm'),
        end: !this.isRecording && end ? formatString(end, 'HH:mm') : null,
        tags: (data.meta.time.tags || []) as Tag[],
        date: new Date(data.start.getTime()),
        duration: parseDurationAsFormat((data.meta.time.timeDiff ?? 0) / 60),
        project: data.meta.time.project as Project,
        task: data.meta.time.task as Task,
        billable: data.meta.time.billable,
        user: timeUser as any,
      },
      { emitEvent: false },
    );
    this.group.setValidators([
      ...(this.isRecording || mode === 'duration' || !this.stateVisibleMode
        ? []
        : [CustomValidators.timeDiffCheck('start', 'end')]),
      CustomValidators.mustChange({
        ...this.group.value,
        date: data.meta.oldStart ? new Date(data.meta.oldStart.getTime()) : new Date(data.start.getTime()),
        start: data.meta.oldStart ? formatString(data.meta.oldStart, 'HH:mm') : formatString(start, 'HH:mm'),
        ...(this.isRecording
          ? { end: undefined }
          : { end: data.meta.oldStart ? formatString(data.meta.oldEnd, 'HH:mm') : formatString(end, 'HH:mm') }),
      }),
    ]);
    if (mode === 'duration') {
      this.group.controls.duration.setValidators([
        Validators.required,
        CustomValidators.validDateFormat('HH:mm'),
        CustomValidators.minDuration(1000),
      ]);
    } else if (mode !== 'range' || this.isRecording) {
      this.group.controls.start.clearValidators(),
        this.group.controls.end.clearValidators(),
        this.group.controls.duration.clearValidators();
    } else {
      this.group.controls.start.setValidators([Validators.required, CustomValidators.validDateFormat('HH:mm')]),
        this.group.controls.duration.setValidators([Validators.required, CustomValidators.validDateFormat('HH:mm')]),
        this.group.controls.end.setValidators([Validators.required, CustomValidators.validDateFormat('HH:mm')]);
    }

    if (this.requireSplit && data.meta.time) {
      const isDE = user.settings?.languageSetting?.split('-', 2)?.[0]?.toLowerCase() === 'de';
      this.splitTimes.next([
        ...splitLongRangeToDay(new Date(data.meta.time.start), new Date()).map((d, i, arr): SplitTime => {
          const isLast = i === arr.length - 1;
          return {
            ...(isLast
              ? { start: startOfDay(d), end: setSeconds(roundToNearestMinutes(d), 0) }
              : { start: setSeconds(d, 0), end: setSeconds(endOfDay(d), 0) }),
            selected: true,
            format: isDE ? 'dd.MM HH:mm' : 'MM/dd HH:mm',
          };
        }),
      ]);
    }
    this._stateVisibleMode = data.meta.time['inputMode'] === 'range'; // set input mode according to time
    this.group.updateValueAndValidity();
    log.debug('data', data.meta.time);
  }
  readonly requireFields$ = this.userSettingsQuery.select().pipe(
    map((x) => x.workspace?.settings),
    map((x) => (x ? { name: !!x.requireName, project: !!x.requireProject, task: !!x.requireTask } : null)),
  );
  get groupErrors() {
    const { errors } = this.group;
    if (!errors) return null;
    if (errors.range) return 'errors.record.time-invalid-range';
    if (errors.same) return 'errors.times.atleast_min';
    return null;
  }
  ngOnInit(): void {
    this.ref.updateSize('500px');
    this.ref.addPanelClass(['mat-dialog-vanilla', 'mat-dialog-relative']);
    this.refreshGroup(this.data);
    this.splitTimes$.subscribe((d) => {
      log.debug('splitTimes', d);
    });
  }
  openUserPicker() {
    if (!this.workspace$isAdmin.value) return;
    return this.dialog
      .open(UserSinglePickerDialogComponent, {
        data: <UserSinglePickerDialogData>{
          selected: this.group.value?.user,
          filter: ({ selected, ...x }) => {
            const user = this.userSettingsQuery.getValue();
            const status = parseSubscriptionAsStatus(user.workspace, user);
            return ((x.has_license || status.isTrial) && !x.removed) || selected;
          },
        },
      })
      .afterClosed()
      .subscribe((user) => {
        if (user)
          this.patchValue({
            user,
          });
      });
  }
  patchValue(data: Partial<typeof this.group.value> = {}, options?: Parameters<typeof this.group.patchValue>[1]) {
    const userId = this.data.meta?.time?.user?.id;
    const user = this.userSettingsQuery.getValue();
    if (data.user === null && userId)
      data.user =
        user.workspace.users.find((x) => x.id === userId) || user.workspace.users.find((x) => x.id === user.id);
    return this.group.patchValue(data, options);
  }
  resetProject() {
    this.patchValue({
      project: this.defaultProject,
      task: null,
    });
  }
  openProjectPicker() {
    return this.dialog
      .open(ClientProjectPickerDialogComponent, {
        data: <ClientProjectDialogData>{
          data: {
            selectedProject: this.group.value.project,
            selectedTask: this.group.value.task,
            closeOnRemove: true,
            canToggle: true,
            defaultProject: this.defaultProject,
          },
        },
      })
      .afterClosed()
      .subscribe((payload) => {
        if (!payload || !payload?.length)
          return ((ctrl) => (ctrl.markAsDirty(), ctrl.markAsTouched()))(this.group.controls.project);
        const [x, t]: [Project, Task] = payload || [];
        const newProject = x ?? this.defaultProject;
        this.patchValue({
          project: newProject,
          task: newProject?.useAsDefault ? null : t,
          billable: x.billable ? true : this.group.value.billable,
        });
      });
  }
  close() {
    if (!this.ref.disableClose) this.ref.close();
  }
  get closeDisabled() {
    return this.ref.disableClose;
  }
  openTagsPicker() {
    return this.dialog
      .open(TagPickerDialogComponent, {
        data: <TagDialogData>{
          data: {
            SelectedTags: this.group.value.tags,
            canToggle: true,
            type: TagType.Time,
            allowTypeModify: false,
          },
        },
      })
      .afterClosed()
      .pipe(filter((x) => !!x))
      .subscribe((x) => this.patchValue({ tags: x }));
  }
  openTaskPicker() {
    const { project, task } = this.group.value;
    if (!project || project.useAsDefault || project.completed) return;
    return this.dialog
      .open(TaskPickerDialogComponent, {
        data: {
          project: project,
          task: task,
        },
      })
      .afterClosed()
      .subscribe((task: Task & { selected: boolean }) => {
        if (task) this.patchValue({ task: task.selected ? task : null });
        else ((ctrl) => (ctrl.markAsDirty(), ctrl.markAsTouched()))(this.group.controls.project);
      });
  }
  openCalPicker() {
    return this.dialog
      .open(TimeDatePickerComponent, {
        data: <TimeDatePickerConfig>{
          selectedDate: new Date(this.group.value.date),
        },
      })
      .afterClosed()
      .pipe(filter((x) => x && isValid(x)))
      .subscribe((x) => {
        this.patchValue({
          date: x,
        });
      });
  }
  isProjectDefault(id: string) {
    return this.defaultProject.id === id;
  }
  get taskDisabled() {
    return !this.group.value.project || this.group.value.project?.useAsDefault || this.group.value.project?.completed;
  }
  async updateSplit() {
    if (this.group.invalid && !this.forceUpdate) return;
    const val = this.group.getRawValue();
    const mode = this.mode;
    const isRecording = this.isRecording;
    const time = this.data.meta.time ? { ...this.data.meta.time } : this.data.meta.time;
    const userSettings = this.userSettingsQuery.getValue();
    const user = this.data.meta.time?.user || userSettings.workspace.users.find((x) => x.id === userSettings.id);
    this.isLoading = true;
    const inputMode = this.stateVisibleMode ? 'range' : 'duration';
    let errorTimes: any[] = [],
      timesCreated: Time[] = [];
    const validSplitTimes = this.splitTimes.value.filter((d) => d.selected);
    const queueTimes = validSplitTimes.map((split) => {
      const start: Date = split.start,
        end: Date = split.end;
      const relativeDateStart = startOfDay(start.getTime());
      const nextDuration = (({ hours, minutes }) => hours * 60 + minutes)(intervalToDuration({ start, end })) * 60;
      return {
        name: val.name,
        billable: !!val.billable,
        client: time.client,
        project: val.project || this.defaultProject,
        outlookCalenderReference: time.outlookCalenderReference,
        timeZone: time.timeZone,
        task: val.task,
        tags: val.tags,
        inputMode,
        ...(mode === 'range_optional'
          ? this.stateVisibleMode
            ? {
                start: start.toISOString(),
                end: end.toISOString(),
                timeDiff: 0,
                inputMode,
              }
            : {
                start: relativeDateStart,
                end: relativeDateStart,
                timeDiff: nextDuration,
                inputMode,
              }
          : mode === 'duration'
            ? {
                timeDiff: nextDuration,
                start: relativeDateStart,
                end: relativeDateStart,
              }
            : { start: start, end: end }),
        recordType: this.isRecording ? 'timer' : 'manual',
        ...(val.user && time.user?.id !== val.user?.id ? { user: { id: val.user.id, name: val.user.name } } : {}),
      };
    });
    await firstValueFrom(this.myTimes.add(...(queueTimes as any[])))
      .then((x) => {
        this.myTimesQuery.__store__.remove(x.filter((y) => y && y.user?.id !== user.id).map((y) => y.id));
        timesCreated.push(...x);
      })
      .catch((err: any) => {
        this.recordService.handleError(err);
        errorTimes.push(err);
      });
    if (time && !time.end && timesCreated.length > 0) {
      await firstValueFrom(this.myTimes.delete(time)).catch((err) => {
        pushError(err);
      });
      (this.myTimesQuery.__store__ as MyTimesStore).remove((x) => !x.end);
    }
    this.isLoading = false;
    return this.ref.close([timesCreated]?.flat?.().filter(Boolean));
  }
  async update() {
    if (this.isRecording && this.requireSplit) return await this.updateSplit();
    if (this.group.invalid && !this.forceUpdate) return;
    const val = this.group.getRawValue();
    const mode = this.mode;
    const isRecording = this.isRecording;
    const stateVisibleMode = this.stateVisibleMode;
    const inputMode = stateVisibleMode ? 'range' : 'duration';
    this.isLoading = true;
    const start: Date = isRecording
        ? this.data.start
        : parseFromString(stateVisibleMode ? val.start : '00:00', 'HH:mm', val.date),
      end: Date = isRecording ? null : parseFromString(stateVisibleMode ? val.end : '00:00', 'HH:mm', val.date);
    const relativeDateStart = startOfDay(start.getTime());
    const time = this.data.meta.time;
    const userSettings = this.userSettingsQuery.getValue();
    const user = this.data.meta.time?.user || userSettings.workspace.users.find((x) => x.id === userSettings.id);
    const nextDuration = isRecording
      ? 0
      : inputMode === 'duration'
        ? parseDurationSeconds(val.duration)
        : (({ hours, minutes }) => hours * 60 + minutes)(intervalToDuration({ start, end })) * 60;
    const nextDurationEnd = addSeconds(start.getTime(), nextDuration);
    return await firstValueFrom(
      this.myTimes.update(
        produce(time, (draft) => {
          return {
            ...draft,
            name: val.name,
            billable: !!val.billable,
            project: val.project || this.defaultProject,
            task: val.task,
            tags: val.tags,
            inputMode,
            ...(isRecording
              ? {
                  start: start.toISOString(),
                  end: null,
                }
              : mode === 'range_optional'
                ? this.stateVisibleMode
                  ? {
                      start: start.toISOString(),
                      end: end.toISOString(),
                      timeDiff: 0,
                      inputMode,
                    }
                  : {
                      start: relativeDateStart.toISOString(),
                      end: relativeDateStart.toISOString(),
                      timeDiff: nextDuration,
                      inputMode,
                    }
                : mode === 'duration'
                  ? {
                      timeDiff: nextDuration,
                      start: start.toISOString(),
                      end: nextDurationEnd.toISOString(),
                      inputMode: 'duration',
                    }
                  : { start: start.toISOString(), end: end.toISOString() }),
            recordType: this.isRecording ? 'timer' : 'manual',
            ...(val.user && time.user?.id !== val.user?.id ? { user: { id: val.user.id, name: val.user.name } } : {}),
          };
        }),
      ),
    )
      .finally(() => {
        this.isLoading = false;
      })
      .then((x) => {
        this.myTimesQuery.__store__.remove(x.filter((y) => y && y.user?.id !== user.id).map((y) => y.id));
        this.ref.close(x);
      })
      .catch((err: any) => {
        this.recordService.handleError(err);
        throw err;
      });
  }
  selectInput(ev: Event, timeInput: ElRefDirective) {
    const el: HTMLElement = timeInput.elementRef.nativeElement;
    if (!el) {
      return;
    }
    const input = el.querySelector('input');
    if (input) {
      ev.preventDefault();
      input.select();
    }
  }

  @ViewChild('timeContextMenuTrigger', { static: true })
  timeContextMenu: MatMenuTrigger;

  timeContextMenuPosition = { x: '0px', y: '0px' };

  onTimeContextMenuTrigger(event: MouseEvent, item: { time: Time; prop: string }) {
    event.stopPropagation(), event.preventDefault();
    this.timeContextMenuPosition.x = event.clientX + 'px';
    this.timeContextMenuPosition.y = event.clientY + 'px';
    this.timeContextMenu.menuData = { $implicit: item };
    this.timeContextMenu.menu.focusFirstItem('mouse');
    this.timeContextMenu.openMenu();
  }
  setToNow(prop: 'start' | 'end') {
    return this.group.controls[prop].patchValue(flow((date) => formatString(date, 'HH:mm'))(new Date()));
  }
  setMinutesDiff(minutes: number, prop: 'start' | 'end') {
    switch (prop) {
      case 'start':
        return this.group.controls[prop].patchValue(
          flow(parsefp(new Date(), 'HH:mm'), subMinutes(minutes), clampDay(new Date()), (date) =>
            formatString(date, 'HH:mm'),
          )(this.group.value.end as string),
        );
      case 'end':
        return this.group.controls[prop].patchValue(
          flow(
            parsefp(new Date(), 'HH:mm'),
            (date) => addMinutes(date, minutes),
            clampDay(new Date()),
            (date) => formatString(date, 'HH:mm'),
          )(this.group.value.start as string),
        );
    }
  }
  setHourDiff(hours: number, prop: 'start' | 'end') {
    switch (prop) {
      case 'start':
        return this.group.controls[prop].patchValue(
          flow(parsefp(new Date(), 'HH:mm'), subHours(hours), clampDay(new Date()), (date) =>
            formatString(date, 'HH:mm'),
          )(this.group.value.end as string),
        );
      case 'end':
        return this.group.controls[prop].patchValue(
          flow(parsefp(new Date(), 'HH:mm'), addHours(hours), clampDay(new Date()), (date) =>
            formatString(date, 'HH:mm'),
          )(this.group.value.start as string),
        );
    }
  }
  setWorkDay() {
    return this.patchValue({
      start: '09:00',
      end: '17:00',
    });
  }
}
