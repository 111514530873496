import { HttpErrorResponse } from '@angular/common/http';
import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import errorUtils from '@app/_classes/error-utils';
import { parseEstimationString, parseEstimationValue } from '@app/_helpers/estimation';
import { toPromise } from '@app/_helpers/promise';
import { distinctUntilChangedJson, hasPermission } from '@app/_helpers/utils';
import { CustomValidators } from '@app/_validators/custom-validators';
import { AppService } from '@app/app.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map } from 'rxjs/operators';
import {
  Client,
  ClientsQuery,
  Project,
  ProjectsQuery,
  ProjectsService,
  UserSettingsQuery,
  WhoCanAddTasks,
} from 'timeghost-api';
import { ClientDialogData, ClientPickerDialogComponent } from '../client-picker-dialog/client-picker-dialog.component';
import { ProjectTemplateDialogComponent } from '../project-template-dialog/project-template-dialog.component';
export type CreateProjectDialogData = {
  search: string;
  type: 'project' | 'template';
  template?: Project;
  navigateOnCreate?: boolean;
  showAdvancedSettings?: boolean;
  client?: Client;
};
@UntilDestroy()
@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.scss'],
})
export class CreateProjectComponent implements OnInit, AfterViewInit, AfterContentInit {
  readonly projectForm = new FormGroup(
    {
      name: new FormControl(this.data?.search || '', [
        Validators.required,
        CustomValidators.minLength(3),
        (ctrl) => {
          const client: Client = ctrl.parent?.value.client;
          const isDefaultClient = !!client?.useAsDefault;
          if (
            client?.id &&
            this.projectQuery.getCount(
              (x) =>
                (!isDefaultClient ? x.client?.id && x.client.id === client.id : true) &&
                x.name?.toLowerCase() === `${ctrl.value}`.toLowerCase(),
            ) > 0
          ) {
            return {
              exists: true,
            };
          }
          return null;
        },
      ]),
      color: new FormControl(this.appService.colorScheme.getColorFromScheme(this.projectQuery.getCount() || 1)),
      client: new FormControl<Client>(this.getDefaultClient(), [Validators.required]),
      estimationType: new FormControl('task'),
      estimation: new FormControl(0),
      template: new FormControl<Project>(null),
      templateCopyTasks: new FormControl(false),
      templateCopyTaskAssign: new FormControl(false),
      templateCopyRate: new FormControl(false),
      private: new FormControl(true),
      billing: new FormControl({
        value: !!this.userSettingsQuery.getValue().workspace?.settings?.isNewProjectBillable,
        disabled: false,
      }),
    },
    { updateOn: 'change' },
  );
  isLoading: boolean = false;
  get formName() {
    return this.projectForm.get('name');
  }
  get formClient() {
    return this.projectForm.get('client');
  }
  get navigateOnCreate() {
    return this.data.navigateOnCreate;
  }
  get showAdvancedSettings() {
    return this.data.showAdvancedSettings;
  }
  color: string;
  showAdvanced: boolean = false;

  constructor(
    private appService: AppService,
    private ref: MatDialogRef<CreateProjectComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: CreateProjectDialogData,
    private projectService: ProjectsService,
    private clientQuery: ClientsQuery,
    private projectQuery: ProjectsQuery,
    private userSettingsQuery: UserSettingsQuery,
    private dialog: MatDialog,
    private router: Router,
    private cdr: ChangeDetectorRef,
  ) {
    this.data = Object.assign(
      { showAdvancedSettings: true, search: '', type: 'project' } as typeof this.data,
      this.data || {},
    );
  }
  getDefaultClient() {
    return this.clientQuery.getAll({ filterBy: (x) => x.useAsDefault })?.[0];
  }
  updateTemplate(template: Project) {
    this.projectForm.patchValue({
      template,
      ...(template?.color ? { color: template.color } : {}),
      client: (template?.client as Client) || this.getDefaultClient(),
      billing: template?.billable,
      estimationType: template?.taskBasedEstimation ? 'task' : 'manual',
      private: !!template?.private,
      estimation: template?.taskBasedEstimation ? 0 : parseEstimationValue(template?.estimation),
      templateCopyTasks: true,
      templateCopyRate: true,
      templateCopyTaskAssign: true,
    });
    this.showAdvanced = true;
    this.cdr.markForCheck();
  }
  ngOnInit() {
    this.ref.updateSize('500px');
    this.ref.addPanelClass(['mat-dialog-vanilla', 'mat-dialog-relative']);
    const template = this.data.template;
    if (template) this.updateTemplate(template);
    else {
      if (this.data.client)
        this.projectForm.patchValue({
          client: this.data.client,
        });
    }

    this.projectForm.valueChanges
      .pipe(
        untilDestroyed(this),
        distinctUntilChangedJson((x) => x.client),
      )
      .subscribe(() => {
        this.projectForm.controls.name.updateValueAndValidity();
      });
    this.projectForm.valueChanges
      .pipe(
        untilDestroyed(this),
        distinctUntilChangedJson((x) => x.templateCopyTasks),
      )
      .subscribe((value) => {
        if (!value.templateCopyTasks && value.templateCopyTaskAssign)
          this.projectForm.patchValue({
            templateCopyTaskAssign: false,
          });
      });
  }
  ngAfterViewInit() {
    this.projectForm.updateValueAndValidity({
      emitEvent: true,
    });
  }
  ngAfterContentInit() {}
  getSelectedUsers(): { id: string; name: string }[] {
    return [];
  }
  get formNameErrors() {
    if (this.formName.errors?.required) return { content: 'errors.required', args: { field: 'Name' } };
    if (this.formName.errors?.minlength) return { content: 'errors.minlength', args: { field: 'Name', length: 3 } };
    if (this.formName.errors?.exists) return { content: 'errors.unique', args: { field: 'Name' } };
    return null;
  }
  readonly workspace$ = this.userSettingsQuery.select((x) => x.workspace);
  readonly workspace$createPermission = this.userSettingsQuery.select().pipe(
    map((user) => {
      return !!user.workspace.permissionSettings?.groupsCanManageProjects?.find((x) => hasPermission(x.id, user));
    }),
  );
  submit() {
    this.isLoading = true;
    const value = this.projectForm.getRawValue();
    const estimation =
      value.estimationType === 'manual'
        ? value.estimation
          ? parseEstimationString(value.estimation.toString(), 'manual')
          : value.estimation
        : 0;
    toPromise(
      this.projectService.add({
        client: value.client,
        whoCanAddTasks:
          (value.template?.whoCanAddTasks ?? value.private) ? WhoCanAddTasks.pm_only : WhoCanAddTasks.everyone,
        description: '',
        name: this.formName.value,
        billable: !!value.billing,
        private: !!value.private,
        estimation,
        taskBasedEstimation: value.estimationType === 'task',
        color: value.color || this.appService.colorScheme.getColorFromScheme(this.projectQuery.getCount()),
        projectType: 'project',
        users: value.template?.users,
        groups: value.template?.groups,
        hourly_rate: value.template?.hourly_rate,
        tags: value.template?.tags ?? [],
        ...(value.template?.id
          ? {
              templateId: value.template.id,
              templateCopyTasks: !!value.templateCopyTasks,
              templateCopyTaskAssign: !!(value.templateCopyTaskAssign && value.templateCopyTasks),
              templateCopyRate: !!value.templateCopyRate,
            }
          : {}),
      } as Partial<Project> as any),
    )
      .then((x) => {
        if (this.data.navigateOnCreate) {
          return this.router.navigate(['/settings/projects', x.id]).then(() => this.close(x));
        }
        return this.close(x);
      })
      .finally(() => {
        this.isLoading = false;
      })
      .catch((err: HttpErrorResponse) => {
        if (errorUtils.isUniqueError(err)) {
          this.formName.setErrors({
            exists: true,
          });
        }
      });
    // this.projectService.add()
  }
  openTemplatePicker() {
    return this.dialog
      .open(ProjectTemplateDialogComponent, {
        data: {
          selected: this.projectForm.value.template,
        },
      })
      .afterClosed()
      .toPromise()
      .then((template: Project) => {
        if (template === undefined) return;
        this.updateTemplate(template);
      });
  }
  openClientPicker() {
    return this.dialog
      .open(ClientPickerDialogComponent, {
        data: <ClientDialogData>{
          canToggle: true,
          closeOnRemove: true,
          selectedClient: this.projectForm.value.client,
        },
      })
      .afterClosed()
      .pipe(filter((x: Client) => !!x?.id))
      .subscribe((x) => {
        this.setClient(x);
      });
  }
  setClient(x?: Client) {
    this.projectForm.patchValue({
      client: x ?? this.getDefaultClient(),
    });
  }
  close(x?: Project) {
    this.ref.close(x);
  }
}
