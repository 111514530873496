import { CdkStepper, StepContentPositionState } from '@angular/cdk/stepper';
import { AfterContentInit, ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { stepAnimation } from './plain-stepper-animation';
@UntilDestroy({})
@Component({
  selector: 'tg-plain-stepper',
  templateUrl: './plain-stepper.component.html',
  providers: [{ provide: CdkStepper, useExisting: PlainStepperComponent }],
  animations: [stepAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlainStepperComponent extends CdkStepper implements OnInit {
  ngOnInit(): void {}
  selectStepByIndex(index: number): void {
    this.selectedIndex = index;
  }
}
