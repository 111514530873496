import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RouteReusableStrategy } from './route-reusable-strategy';
import { I18nService } from './i18n.service';
import { HttpService } from './http/http.service';
import { HttpCacheService } from './http/http-cache.service';
import { ApiPrefixInterceptor } from './http/api-prefix.interceptor';
import { ErrorHandlerInterceptor } from './http/error-handler.interceptor';
import { CacheInterceptor } from './http/cache.interceptor';

import {
  UserService,
  ProjectsService,
  TasksService,
  TimesService,
  ClientsService,
  TagsService,
  WorkspacesService,
  SearchService,
  Logger,
} from 'timeghost-api';
import { SatPopoverAnchoringService } from '@ncstate/sat-popover';
import { UtilService } from '@app/_services/util.service';
import {
  MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS as MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatLegacySnackBarConfig as MatSnackBarConfig,
} from '@angular/material/legacy-snack-bar';

const log = new Logger('CoreModule');
@NgModule({
  imports: [CommonModule, HttpClientModule, HttpClientJsonpModule, TranslateModule, RouterModule],
  providers: [
    HttpCacheService,
    ApiPrefixInterceptor,
    ErrorHandlerInterceptor,
    CacheInterceptor,
    {
      provide: HttpClient,
      useClass: HttpService,
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy,
    },
    ClientsService,
    ProjectsService,
    WorkspacesService,
    TagsService,
    TasksService,
    TimesService,
    UserService,
    UtilService,
    SearchService,
    SatPopoverAnchoringService,
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: <MatSnackBarConfig>{
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 3000,
      },
    },
  ],
})
export class CoreModule {
  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule,
    private i18n: I18nService,
    private translateService: TranslateService,
  ) {
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
