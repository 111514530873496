import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { createRxValue } from '@app/_helpers/utils';
import { Task } from 'timeghost-api';
type TaskPickerEntry = Task & {
  selected?: boolean;
};
@Component({
  selector: 'tg-task-picker-entry',
  templateUrl: './task-picker-entry.component.html',
  styleUrls: ['./task-picker-entry.component.scss'],
})
export class TaskPickerEntryComponent implements OnInit {
  @Output() select = new EventEmitter<Task>();
  constructor() {}

  ngOnInit(): void {}
  readonly entry$ = createRxValue<TaskPickerEntry>();
  get entry() {
    return this.entry$.value;
  }
  @Input()
  set entry(val: TaskPickerEntry) {
    this.entry$.value = val;
  }
  @ContentChild('customMenu')
  customMenu: TemplateRef<any>;
}
