<div matDialogTitle class="flex flex-row items-center justify-start">
  <span>{{ 'TimeRounding' | translate }}</span>
  <div class="flex-auto"></div>
  <button mat-icon-button matDialogClose tabindex="-1">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <form class="my-4 flex flex-col gap-6" (submit)="$event.preventDefault()" [formGroup]="this.roundingGrp">
    <div class="mx-1 flex flex-row items-center justify-start" *ngIf="!hideToggle">
      <span>{{ 'RoundingOn' | translate }}</span>
      <div class="flex-auto"></div>
      <mat-slide-toggle formControlName="roundingState" color="primary"></mat-slide-toggle>
    </div>
    <div class="flex flex-row items-center justify-start gap-4">
      <mat-form-field appearance="fill" class="mat-form-field-plain mat-form-field-compact wflex-auto">
        <mat-select formControlName="roundingType" placeholder="Round Type">
          <mat-option *ngFor="let rType of this.getRoundingTypes$(); let i = index" [value]="i">
            {{ this.parseDisplayRoundingType(this.roundingTypes[rType]) | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" class="mat-form-field-plain mat-form-field-compact wflex-auto">
        <mat-select formControlName="roundingValue" placeholder="Round Value" *ngIf="durArray as dur">
          <mat-option *ngFor="let d of dur.values; let i = index" [value]="d">
            {{ d * 60 * 1000 | dfnsFormatDistanceStrict: dur.ref }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="mat-action-filled flex flex-col">
  <button mat-flat-button class="mat-action-filled self-stretch" color="primary" (click)="this.submit()">
    {{ 'utils.apply' | translate }}
  </button>
</mat-dialog-actions>
