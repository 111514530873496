import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild, computed, model, signal } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { createRxValue } from '@app/_helpers/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Logger, Project, Task, UserSettingsQuery } from 'timeghost-api';

import { hasCreateTaskPermission, hasPermissionByKey } from '@app/_helpers/permission';
import {
  CreateTaskDialogComponent,
  CreateTaskDialogData,
} from '@app/components/create-task-dialog/create-task-dialog.component';
import { TimeTrackCreateData } from '@app/shared/time-tracker-calendar-create-dialog/time-tracker-calendar-create-dialog.component';
import { ProjectListComponent } from '@app/shared/time-tracker-calendar-stepper-create-dialog/project-list/project-list.component';
import { TaskListComponent } from '@app/shared/time-tracker-calendar-stepper-create-dialog/task-list/task-list.component';
import { pick } from 'lodash-es';
import { combineLatest, map, switchMap } from 'rxjs';
import { CreateProjectComponent, CreateProjectDialogData } from '../create-project/create-project.component';

const log = new Logger('ClientProjectPickerStepperDialogComponent');
type DialogData = TimeTrackCreateData & { skipProjectStepper: boolean };
export let clientProjectDialogId: string;
@UntilDestroy()
@Component({
  selector: 'app-client-project-picker-stepper-dialog',
  templateUrl: './client-project-picker-stepper-dialog.component.html',
  styleUrls: ['./client-project-picker-stepper-dialog.component.scss'],
  host: {
    class: 'search-dialog-host',
  },
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ClientProjectPickerStepperDialogComponent implements OnInit {
  loading = signal(false);
  stepIndex = model(0);
  readonly selected = createRxValue(pick(this.src, 'project', 'task'), {
    startWithValue: pick(this.src, 'project', 'task'),
  });
  readonly canManageProject$ = this.userSettingsQuery.select((x) => hasPermissionByKey(x, 'groupsCanManageProjects'));
  readonly canCreateTask$ = combineLatest([this.userSettingsQuery.select(), this.selected.asObservable()]).pipe(
    map(([user, s]) => {
      if (s?.project?.useAsDefault) return false;
      return hasCreateTaskPermission(user, s?.project);
    }),
  );
  @ViewChild(ProjectListComponent, { static: true }) private projectListComponent: ProjectListComponent;
  @ViewChild(TaskListComponent, { static: true }) private taskListComponent: ProjectListComponent;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    private src: DialogData,
    private ref: MatDialogRef<ClientProjectPickerStepperDialogComponent>,
    private dialog: MatDialog,
    private userSettingsQuery: UserSettingsQuery,
  ) {}
  get userSettings() {
    return this.userSettingsQuery.getValue();
  }
  readonly searchinput = new FormControl('');
  requireProject = computed(() => {
    const user = this.userSettingsQuery.getValue();
    const { project } = this.selected.value;
    return user.workspace.settings?.requireProject && !project;
  });
  requireTask = computed(() => {
    const user = this.userSettingsQuery.getValue();
    const { task } = this.selected.value;
    return user.workspace.settings?.requireTask && !task;
  });
  disabledNext = computed(() => {
    const index = this.stepIndex();
    return index === 0 ? this.requireProject() : index === 1 ? this.requireTask() : false;
  });
  onSelectProject(entity?: { project: Project; task?: Task }, closeOnTask?: boolean) {
    if (entity)
      this.selected.update((s) => {
        if (this.stepIndex() === 0) s.project = entity.project;
        s.task = entity.task;
        return s;
      }, true);
    if (entity?.project?.useAsDefault) {
      const { project } = this.selected.value;
      this.close(project, null);
      return;
    }
    if (entity?.task && entity?.task?.id !== this.selected.value?.task?.id && closeOnTask && this.stepIndex() === 1) {
      const { project, task } = this.selected.value;
      this.close(project, task);
      return;
    }
    if ((!entity || entity.project) && this.stepIndex() === 0) this.selectedIndexChange(1);
    else if (this.stepIndex() === 1) {
      const { project, task } = this.selected.value;
      this.close(project, task);
    }
  }
  selectedIndexChange(stepIndex: number) {
    this.stepIndex.set(stepIndex);
    setTimeout(() => {
      (stepIndex === 0 ? this.projectListComponent : stepIndex === 1 ? this.taskListComponent : null)?.focusSearch();
    });
  }
  prevStep() {
    this.selectedIndexChange(this.stepIndex() - 1);
  }
  ngOnInit(): void {
    clientProjectDialogId = this.ref.id;
    this.ref
      .afterClosed()
      .toPromise()
      .finally(() => (clientProjectDialogId = null));
    this.ref.updateSize('760px', '620px');
    this.ref.addPanelClass(['mat-dialog-vanilla', 'mat-dialog-relative']);
    const project = this.selected.value?.project;
    if (this.src?.skipProjectStepper === true || (project && !project.useAsDefault)) this.selectedIndexChange(1);

    this.ref
      .beforeClosed()
      .pipe(
        switchMap(async () => {
          const { project, task } = this.selected.value ?? {};
          this.close(project, task);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }
  async createProject() {
    this.loading.set(true);

    const newProject = await new Promise((resolve, reject) => {
      this.dialog
        .open(CreateProjectComponent, {
          data: <CreateProjectDialogData>{
            navigateOnCreate: false,
            search: this.projectListComponent?.search.value,
            type: 'project',
          },
        })
        .afterClosed()
        .toPromise()
        .finally(() => {
          this.loading.set(false);
        })
        .then(resolve)
        .catch(reject);
    });
    if (newProject) {
      this.selected.update((s) => {
        s.project = newProject as Project;
        s.task = null;
        return s;
      }, true);
      this.selectedIndexChange(1);
    }
  }
  async createTask() {
    const { project } = this.selected.value;
    this.loading.set(true);

    const newTask = await new Promise((resolve, reject) => {
      this.dialog
        .open(CreateTaskDialogComponent, {
          data: <CreateTaskDialogData>{
            project,
            search: this.taskListComponent?.search.value,
          },
        })
        .afterClosed()
        .toPromise()
        .finally(() => {
          this.loading.set(false);
        })
        .then(resolve)
        .catch(reject);
    });
    if (newTask) {
      this.selected.update((s) => {
        s.task = newTask as Task;
        return s;
      }, true);
      this._closeWithSelected();
    }
  }
  private _closeWithSelected() {
    const { project, task } = this.selected.value;
    return this.close(project, task);
  }
  close(project: Project, task?: Task) {
    this.ref.close([project, task?.project?.id ? (task.project.id !== project.id ? null : task) : null]);
  }
}

export { DialogData as ClientProjectPickerStepperData };
