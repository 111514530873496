import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateRange } from '@angular/material/datepicker';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TimeTrackCreateData } from '@app/shared/time-tracker-calendar-create-dialog/time-tracker-calendar-create-dialog.component';
import { TimeTrackerCalendarStepperCreateDialogComponent } from '@app/shared/time-tracker-calendar-stepper-create-dialog/time-tracker-calendar-stepper-create-dialog.component';
import { createRxValue, distinctUntilChangedJson, fromRxValue } from '@app/_helpers/utils';
import defaults from '@env/defaults';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  addDays,
  addMinutes,
  differenceInDays,
  differenceInSeconds,
  endOfDay,
  endOfWeek,
  isSameDay,
  isSameWeek,
  isValid,
  isWithinInterval,
  startOfDay,
  startOfMinute,
  startOfWeek,
  subDays,
  subMonths,
} from 'date-fns/esm';
import { debounce, merge } from 'lodash-es';
import { combineLatest, debounceTime, distinctUntilChanged, filter, map, shareReplay, startWith, tap } from 'rxjs';
import {
  ApplicationSettingsQuery,
  FeedEntry,
  FeedQuery,
  FeedService,
  Logger,
  Project,
  Scope,
  UserService,
  UserSettingsQuery,
} from 'timeghost-api';

const parseScopeToTypes = (scopes: Scope[]): string[] => {
  return scopes.map((x) => {
    return parseScopeToType(x);
  });
};
export type FeedSuggestionsType = { id: string; name: string; useAsDefault?: boolean; tasks: Task[] } & {
  [key: string]: any;
};
const log = new Logger('FeedService');
const parseScopeToType = (scope: string) => {
  switch (scope) {
    case Scope.Groups:
      return 'plannerTask';
    case Scope.OutlookTasks:
      return 'todoTask';
    case Scope.Calendar:
      return 'calendar';
    case Scope.DevOpsOnline:
      return 'devops';
    case Scope.RecentFiles:
      return 'file';
    case Scope.Sites:
      return 'spfile';
    case Scope.Messages:
      return 'mail';
    case Scope.TeamsCalls:
      return 'Call';
    default:
      return scope.toString();
  }
};
const parseScopeObjToType = (scopes: { [key: string]: any }): string[] => {
  return Object.keys(scopes)
    .map((x) => x as Scope)
    .filter((x) => scopes[x] !== false)
    .map((x) => {
      switch (x) {
        case Scope.Groups:
          return 'plannerTask';
        case Scope.OutlookTasks:
          return 'todoTask';
        case Scope.Calendar:
          return 'calendar';
        case Scope.DevOpsOnline:
          return 'devops';
        case Scope.RecentFiles:
          return 'file';
        /** @ts-ignore */
        case Scope.Sites:
          return 'spfile';
        case Scope.Messages:
          return 'mail';
        case Scope.TeamsCalls:
          return 'Call';
        default:
          return x?.toString?.();
      }
    });
};
type FilterScopes = Partial<{ [key: string]: boolean }>;
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class FeedPageService {
  constructor(
    private userSettingsQuery: UserSettingsQuery,
    private feedQuery: FeedQuery,
    private feedService: FeedService,
    private dialog: MatDialog,
    private appSettings: ApplicationSettingsQuery,
    private userService: UserService,
  ) {}
  readonly isLoading = createRxValue(false);
  readonly isLoading$ = combineLatest([
    this.feedQuery.selectLoading().pipe(startWith(this.isLoading.value)),
    this.isLoading.asObservable(),
  ]).pipe(map(([feedLoading, selfLoading]) => feedLoading || selfLoading));
  get feedLoading() {
    return this.feedQuery.getValue().loading === true;
  }
  readonly search = new FormControl('');
  readonly search$ = this.search.valueChanges;
  readonly viewDate = createRxValue(new DateRange<Date>(new Date(), new Date()));
  readonly viewDate$ = this.viewDate.asObservable().pipe(filter((x) => !!x?.start && !!x.end));
  readonly viewDate$status = this.viewDate$.pipe(
    map(({ start, end }) => {
      const sameDay = isSameDay(start, end);
      return {
        start,
        end,
        isToday: isSameDay(start, end) && isSameDay(start, new Date()),
        sameDay,
      };
    }),
  );
  minRangeStart = addDays(subMonths(new Date(), 6), 1);
  readonly filterScopes = createRxValue<FilterScopes>({}, { startWithValue: {} });
  readonly feedEntries = fromRxValue(
    combineLatest([this.feedQuery.selectAll(), this.viewDate.asObservable().pipe(distinctUntilChangedJson())]).pipe(
      map(([entries, date]) => {
        return (
          entries
            ?.filter((e, edx, arr) => {
              const start = new Date(e.start);
              const validDate =
                isValid(start) && isWithinInterval(start, { start: startOfDay(date.start), end: endOfDay(date.end) });
              if (!validDate) return false;
              // if (
              //   e.type === 'calendar' &&
              //   arr.findIndex((ed, edix) => ed.refIds?.find((d) => d === e.id) && edix !== edx) !== -1
              // )
              //   return null;
              return true;
            })
            ?.filter(Boolean)
            ?.map((e) => ({ ...e }))
            .map((e, edx, arr) => {
              if (e.booked && e.refIds) {
                arr
                  .filter((x) => e.refIds.includes(x.id) && e.id !== x.id)
                  .forEach((ref) => {
                    ref.booked = e.booked;
                  });
              }
              return e;
            }) ?? []
        );
      }),
      tap((...args) => {
        log.debug('entries', ...args);
      }),
    ),
    [],
    this.feedQuery.getAll(),
  );
  readonly feedEntries$scopes = this.feedEntries
    .asObservable(true)
    .pipe(
      map(
        (x): Partial<{ [K in Scope]: boolean }> =>
          x
            ?.map((y) => y.type)
            .reduce((l, r) => {
              if (l[r] === undefined) {
                l[r] = null;
              }
              return l;
            }, {}) ?? {},
      ),
    )
    .pipe(shareReplay());
  readonly filteredScopes$ = fromRxValue(
    combineLatest([this.feedEntries$scopes, this.filterScopes.asObservable()]).pipe(
      map(([scopes, filtered]) => {
        return merge({}, scopes, filtered);
      }),
    ),
  );
  readonly showBooked = fromRxValue(this.userSettingsQuery.select((x) => x?.settings?.feedShowBooked));
  readonly filteredEntries$ = combineLatest([
    this.feedEntries.asObservable(),
    this.filteredScopes$.asObservable(),
    this.showBooked.asObservable(),
    this.search$.pipe(startWith(''), distinctUntilChanged()),
  ]).pipe(
    debounceTime(50),
    map(([entries, scopes, showBooked, search]) => {
      return [
        ((_entries) => (showBooked ? _entries : _entries.filter((x) => !x.booked)).filter((x) => !x.hidden))(entries),
        scopes,
        search,
      ] as [FeedEntry[], FilterScopes, string];
    }),
    map(([entries, scopes, search]) => {
      return this.parseFilteredData(entries, scopes, search);
    }),
  );
  readonly availableScopes$ = combineLatest([this.feedEntries$scopes, this.filteredScopes$.asObservable()]).pipe(
    distinctUntilChanged(),
    map(([scopes, scopeFilter]) => {
      const mappedScopes = merge({}, scopes, scopeFilter);
      return {
        ...mappedScopes,
        includes: (scope: string) => mappedScopes[parseScopeToType(scope)] !== undefined,
        enabled: (scope: string) => scopeFilter[parseScopeToType(scope)] !== false,
      };
    }),
  );
  readonly availableScopes$custom = combineLatest([this.availableScopes$, this.filteredScopes$.asObservable()]).pipe(
    map(([scopes, scopeFilter]) => {
      const newScopes = Object.entries(scopes)
        .filter(([key, value]) => typeof value !== 'function' && key.indexOf('custom#') === 0)
        .map(([key, value]) => ({ key, value }));
      return {
        scopes: newScopes,
        includes: scopes.includes,
        enabled: scopes.enabled,
      };
    }),
  );

  readonly feedSuggestions = createRxValue<FeedSuggestionsType[]>(null);
  feedSuggestionsCacheId: string = null;
  showBookedLoading = false;
  readonly changeShowBooked = debounce(() => {
    this.showBookedLoading = true;
    return this.userService
      .changeSettings({
        feedShowBooked: !this.userSettingsQuery.getValue()?.settings?.feedShowBooked,
      })
      .toPromise()
      .then(() => {
        this.showBookedLoading = false;
      });
  }, 250);
  prev() {
    const viewDate = this.viewDate.value;
    let days = differenceInDays(viewDate.end, viewDate.start).clampThis(0) + 1;
    return this.updateRange(new DateRange<Date>(subDays(viewDate.start, days), subDays(viewDate.end, days)));
  }
  next() {
    const viewDate = this.viewDate.value;
    let days = differenceInDays(viewDate.end, viewDate.start).clampThis(0) + 1;
    return this.updateRange(new DateRange<Date>(addDays(viewDate.start, days), addDays(viewDate.end, days)));
  }

  async updateRange(date: DateRange<Date>) {
    this.isLoading.update(true);
    this.viewDate.value = date;
    await this.reloadCalendar().finally(() => {
      this.isLoading.update(false);
    });
  }
  async reloadCalendar() {
    if (!this.viewDate.value) return await this.updateRange(new DateRange<Date>(new Date(), new Date()));
    const date = this.viewDate.value;
    return this.feedService.load(startOfDay(date.start), endOfDay(date.end));
  }
  async createTime({ data }: { data: FeedEntry }, project?: Project) {
    window.dataLayer.push({
      event: 'feed_drop',
      data: data.type,
    });
    const start = new Date(data.start),
      end = ((dt) =>
        differenceInSeconds(dt, start) <= 0 ? addMinutes(start.getTime(), defaults.MIN_DURATION_MINUTES) : dt)(
        new Date(data.end || data.start),
      );
    this.dialog
      .open(TimeTrackerCalendarStepperCreateDialogComponent, {
        data: <TimeTrackCreateData>{
          start: startOfMinute(start),
          end: startOfMinute(end),
          title: data.name,
          project,
          billable: !!project?.billable,
          lang: {
            submit: 'feed.entry.save',
          },
          outlookRefId: data.id,
        },
      })
      .afterClosed()
      .pipe(filter((x) => !!x))
      .subscribe();
  }
  private parseFilteredData(entries: FeedEntry[], scopes: FilterScopes, search: string) {
    const searchExp = new RegExp(search, 'i');
    const enabledTypes = parseScopeObjToType(scopes);
    const validSearch = typeof search === 'string' && search.length >= 2;
    return entries.filter(
      (x) =>
        x?.id &&
        (!validSearch || !!x.name?.match(searchExp)?.length || !!x.description?.match(searchExp)?.length) &&
        (x.type === undefined || enabledTypes.length === 0 || enabledTypes.includes(x.type)),
    );
  }
  get rangeType() {
    const w = this.appSettings.getValue().config?.feedView;
    return w === 1 ? 'week' : w === 0 ? 'day' : null;
  }
  toggleFilterScope(scope: Scope | string) {
    if (!scope) return;
    const keyName = parseScopeToType(scope);
    this.filterScopes.update((state) => ({ ...state, [keyName]: !(state[keyName] !== false) }));
  }
  private initialized = false;
  initialize() {
    if (this.initialized) return;
    this.initialized = true;
    // if (this.feedQuery.getValue() && !this.feedQuery.getValue().loading) this.reloadCalendar();

    if (this.rangeType === 'week')
      this.viewDate.value = new DateRange(
        startOfWeek(this.viewDate.value.start, { weekStartsOn: 1 }),
        endOfWeek(this.viewDate.value.start, { weekStartsOn: 1 }),
      );

    this.appSettings
      .select((x) => x.config?.feedView)
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
        map((w) => (w === 1 ? 'week' : w === 0 ? 'day' : null)),
      )
      .subscribe((w) => {
        const week = startOfWeek(this.viewDate.value.start, { weekStartsOn: 1 });
        if (w === 'day')
          this.viewDate.value = isSameWeek(week, new Date(), { weekStartsOn: 1 })
            ? new DateRange(startOfDay(new Date()), endOfDay(new Date()))
            : new DateRange(week, endOfDay(week.getTime()));
        else if (w === 'week')
          this.viewDate.value = new DateRange(week, endOfWeek(week.getTime(), { weekStartsOn: 1 }));
      });
  }
}
